import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import Redemtion_Popup from '../components/folio-redemtion-popup';
import Sip_Popup from '../components/folio-sip-popup';
import Additional_Purchase_Popup from '../components/folio-additional-purchase-popup';
import Switch_Popup from '../components/folio-switch-popup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { detailedPortfolio, familyWisePortfolio } from '../apisMethods';
import { notify } from './toastCreater';
import { globalInfo } from '../App';
import { CheckPathRediction } from './helpers/redirection';
import FolioDetailPopup from '../components/folio-detailPopup';



const Portfolio = () => {

  const { setLoader } = useContext(globalInfo)
  const navigate = useNavigate();
  const [redemptionShow, setRedemptionShow] = useState(false)
  const [additionalShow, setAdditionalShow] = useState(false)
  const [sipShow, setSipShow] = useState(false)
  const [switchShow, setSwitchShow] = useState(false)
  const [reviewdata, setReviewdata] = useState({
    golds: [], debts: [], equitys: [],
    goldPurchaseSubTotal: 0,
    goldCurrentvalueSubTotal: 0,
    goldGainSubTotal: 0,
    debtPurchaseSubTotal: 0,
    debtCurrentvalueSubTotal: 0,
    debtGainSubTotal: 0,
    equityPurchaseSubTotal: 0,
    equityCurrentvalueSubTotal: 0,
    equityGainSubTotal: 0
  })
  const [applicantName, setApplicantName] = useState('')
  const [grandTotal, setGrandTotal] = useState({})
  const location = useLocation();
  const [familyWiseData, setFamilyWiseData] = useState([]);
  const [applicantArray, setApplicantArray] = useState([]);
  const [headers, setHeaders] = useState({});
  const [addionalSchemeData, setAddionalSchemeData] = useState({});
  const [sipSchemeData, setSipSchemeData] = useState({});
  const [switchSchemeData, setSwitchSchemeData] = useState({});
  const [redumptionData, setRedumptionData] = useState({});
  const [openFolioDetailPopup, setOpenFolioDetailPopup] = useState(false);
  const [folioData, setFolioData] = useState([])




  useEffect(() => {
    let token = localStorage.getItem("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    const portfolioData = JSON.parse(localStorage.getItem("portfolioData"));
    setHeaders(header)
    if (userdata?.pan) {
      familyWisePortfolioCall(userdata?.pan, header)
      if (location?.state) {
        setApplicantName(location.state?.name)
        detailedPortfolioCall(header, location.state)
      } else if (portfolioData?.pan || portfolioData?.gpan) {
        setApplicantName(portfolioData?.name)
        detailedPortfolioCall(header, portfolioData)
      }
      else {
        const reqData = {
          pan: userdata?.pan,
          gpan: "",
          name: userdata?.displayName.toUpperCase()
        }
        setApplicantName(userdata?.displayName)
        detailedPortfolioCall(header, reqData)
      }
    } else if (token) {
      navigate("/dashboard/Profile-creation")
    } else {
      navigate("/")
    }




  }, [])

  const getPotfolioDetail = (e) => {
    localStorage.setItem("portfolioData", JSON.stringify( e?.body));
    detailedPortfolioCall(headers, e?.body)
    setApplicantName(e.label)
    // setLoader('none')
  }
  const familyWisePortfolioCall = (pan, headers) => {
    setLoader('block')
    let tempArr = []
    familyWisePortfolio({ pan }, headers).then((res) => {
      if (!res.data) {
        notify("warn", "you have no family member")
        setFamilyWiseData([])
      } else {
        setFamilyWiseData(res.data?.finalArray)
        let arr = res.data?.finalArray
        arr?.forEach((data, i) => {
          let obj = { value: data?.Pan ? data?.Pan : `${i}Gpan_${data?.Gpan}`, label: data.Name, body: { gpan: data?.Gpan, pan: data?.Pan, name: data?.Name } }
          tempArr.push(obj)
        })
      }
      setLoader('none')
    })
    setApplicantArray(tempArr)
  }

  const detailedPortfolioCall = (header, selectedReq) => {
    setLoader("block")
    let reqData = selectedReq
    if (selectedReq) {
      reqData = selectedReq
    }
    console.log("rrr", reqData)
    detailedPortfolio(reqData, header).then((res) => {
      if (!res.data) {
        setLoader("none")
        notify("warn", "you have no transactions")
        setReviewdata({
          golds: [], debts: [], equitys: [],
          goldPurchaseSubTotal: 0,
          goldCurrentvalueSubTotal: 0,
          goldGainSubTotal: 0,
          debtPurchaseSubTotal: 0,
          debtCurrentvalueSubTotal: 0,
          debtGainSubTotal: 0,
          equityPurchaseSubTotal: 0,
          equityCurrentvalueSubTotal: 0,
          equityGainSubTotal: 0
        })
      } else {
        const reformatedData = reformatData(res.data?.dataSent?.data);
        setReviewdata(reformatedData)
        setGrandTotal(res.data?.dataSent)
        setLoader("none")
      }
    })

  }

  const reformatData = (data) => {
    setLoader("block")
    const golds = [];
    const debts = [];
    const equitys = []
    let goldPurchaseSubTotal = 0;
    let goldCurrentvalueSubTotal = 0
    let goldGainSubTotal = 0

    let debtPurchaseSubTotal = 0;
    let debtCurrentvalueSubTotal = 0
    let debtGainSubTotal = 0

    let equityPurchaseSubTotal = 0;
    let equityCurrentvalueSubTotal = 0
    let equityGainSubTotal = 0



    data?.forEach(el => {
      if (el?.gold > 0) {
        goldPurchaseSubTotal += Number(el.purchase)
        // goldCurrentvalueSubTotal += Number(el.currentvalue))
        goldCurrentvalueSubTotal += Number(el.currentvalue)
        goldGainSubTotal += Number(el.gain)
        golds.push({ ...el, gold: el.gold, })
      } else if (el?.debt > 0) {
        debtPurchaseSubTotal += Number(el.purchase)
        debtCurrentvalueSubTotal += Number(el.currentvalue)
        debtGainSubTotal += Number(el.gain)
        debts.push({ ...el, debt: el.debt })
      } else {
        equityPurchaseSubTotal += Number(el?.purchase)
        equityCurrentvalueSubTotal +=Number(el?.currentvalue)
        equityGainSubTotal += Number(el?.gain)
        equitys.push({ ...el, equity: el?.equity })
      }
    })
    
    setLoader("none")
    return { golds, debts, equitys, goldPurchaseSubTotal, goldCurrentvalueSubTotal, goldGainSubTotal, debtPurchaseSubTotal, debtCurrentvalueSubTotal, debtGainSubTotal, equityPurchaseSubTotal, equityCurrentvalueSubTotal, equityGainSubTotal }
  }

  const propMethods = (data, set, dataSet) => {
    const isLoggedIn = CheckPathRediction()
    if (!isLoggedIn?.token) {
      navigate("/");
      return
    } else if (!isLoggedIn?.pan && !isLoggedIn?.iin && isLoggedIn?.token) {
      navigate("/dashboard/Profile-creation");
    } else if (isLoggedIn?.pan && !isLoggedIn?.iin) {
      navigate("/dashboard/Profile-creation");
    } else {
      dataSet({ ...data })
      set(true)
    }



  }

  const transactionDetails = (productcode, folio, rta,grandTotal) => {

    let reqBody = { productcode, folio, rta,grandTotal }
    setFolioData(reqBody)
    setOpenFolioDetailPopup(true)
  }



  const mapReview = (array) => {
    return array.map((data) => {
      return <>
        <tr>
          <td onClick={() => transactionDetails(data?.productcode, data?.folio, data?.rta,data?.currentvalue)} className="underline curser"> {data.scheme}</td>
          <td>{data?.folio}</td>
          <td>{data?.unit}</td>
          <td>{Math.round(data?.purchase)}</td>
          <td>{data?.cnav}</td>
          <td>{Math.round(data?.currentvalue)}</td>
          <td>{Math.round(data?.gain)}</td>
          <td>{data.days}</td>
          <td >{data.absolute}</td>
          <td>{data.finalcagr}</td>
          <td>
            <div className="d-flex">
              <span title="Add. Purchase"><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/new_puchase.png"} className="icon-contain ms-3" onClick={() => propMethods(data, setAdditionalShow, setAddionalSchemeData)} /></span>
              <span title="SIP"><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/bar_chart.png"} className="icon-contain ms-3" onClick={() => propMethods(data, setSipShow, setSipSchemeData)} /></span>
              <span title="Switch"><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/switch.png"} className="icon-contain ms-3" onClick={() => propMethods(data, setSwitchShow, setSwitchSchemeData)} /></span>
              <span title='Redemption'><img src={"https://bfc-prodigypro-assets.s3.ap-southeast-1.amazonaws.com/redemption.png"} className="icon-contain ms-3" onClick={() => propMethods(data, setRedemptionShow, setRedumptionData)} /> </span>

            </div>
          </td>
        </tr>
      </>
    })
  }


  const showTotal = (purchase, current, gain) => {
    if (!purchase) {
      return
    }
    return <tr className="bg-gray ">
      <td className="fw-600">Sub Total</td>
      <td></td>
      <td></td>
      <td>{Math.round(purchase)}</td>
      <td></td>
      <td>{Math.round(current)}</td>
      <td>{Math.round(current-purchase)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  }




  return (
    <>
      <div className="wrapper pe-5">
        <div className="report px-lg-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
            </ol>
          </nav>
        </div>
        {/* <Loader/> */}
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 px-lg-5 pb-3 pt-4">
                <h6 class="heading-cust"> Report On Unrealized Gains Only</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 px-lg-5">
                <div className="portfolio px-lg-3">
                  <label className="pb-2">Select Applicant Name</label>
                  <Select options={applicantArray} placeholder={applicantName ? applicantName : "Select Applicant name"
                  } onChange={getPotfolioDetail} className="single-drop" />
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="portfolio mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 px-lg-5">
                <div className="detail-port p-2">
                  <div className="port-h">
                    <Table bordered striped responsive>
                      <thead>
                        <tr className="text-red bg-light-red text-lg-start text-center position-sticky top-0">
                          <th className="wd-20rem">Scheme / Company</th>
                          <th className="wd-5rem">Folio</th>
                          <th className="wd-7rem">Balance Units</th>
                          <th className="wd-7rem">Purchase Cost</th>
                          <th className="wd-7rem">Current NAV</th>
                          <th className="wd-7rem">Market Value</th>
                          <th className="wd-5rem">Gain/Loss</th>
                          <th className="wd-5rem">Avg. Days</th>
                          <th className="wd-8rem">Absolute Return %</th>
                          <th className="wd-5rem">CAGR %</th>
                          <th>Transact</th>
                        </tr>
                        <p className="pt-2 text-red">{applicantName}</p>

                      </thead>
                      <tbody className="text-lg-start text-center">
                        {reviewdata?.debts?.length > 0 && <p className="text-primary">Debt</p>}
                        {reviewdata?.debts?.length > 0 && mapReview(reviewdata?.debts).map((data) => {
                          return data
                        })}
                        {showTotal(reviewdata.debtPurchaseSubTotal, reviewdata?.debtCurrentvalueSubTotal, reviewdata.debtGainSubTotal)}

                        {reviewdata.equitys.length > 0 && <p className="text-primary">Equity</p>}
                        {reviewdata.equitys.length > 0 && mapReview(reviewdata.equitys).map((data) => {
                          return data
                        })} 
                        {showTotal(reviewdata.equityPurchaseSubTotal, reviewdata.equityCurrentvalueSubTotal, reviewdata.equityGainSubTotal)}


                        {reviewdata.golds.length > 0 && <p className="text-primary">Gold</p>}
                        {reviewdata.golds.length > 0 && mapReview(reviewdata.golds).map((data) => {
                          return data
                        })}
                        {showTotal(reviewdata?.goldPurchaseSubTotal, reviewdata.goldCurrentvalueSubTotal, reviewdata.goldGainSubTotal)}



                        <tr className="bg-light-red fw-bold">
                          <td>Grand Total</td>
                          <td></td>
                          <td></td>
                          <td>{Math.round(grandTotal?.Totalpurchase)}</td>
                          <td></td>
                          <td>{Math.round(grandTotal?.TotalMarketValue)}</td>
                          <td>{Math.round(grandTotal?.Gainloss)}</td>
                          <td></td>
                          <td></td>
                          <td>{grandTotal?.finalcagr}</td>
                          <td></td>

                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Redemtion_Popup data={redumptionData} show={redemptionShow} setShow={setRedemptionShow} />
        <Sip_Popup data={sipSchemeData} show={sipShow} setShow={setSipShow} />
        <Switch_Popup data={switchSchemeData} show={switchShow} setShow={setSwitchShow} />
        <Additional_Purchase_Popup data={addionalSchemeData} show={additionalShow} setShow={setAdditionalShow} />
        <FolioDetailPopup show={openFolioDetailPopup} setShow={setOpenFolioDetailPopup} data={folioData} />
      </div>

    </>
  )
}
export default Portfolio 