import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { notify } from "../pages/toastCreater";
import {
  getUserProfileViaPortfolio,
  redeem,
  saveNseTransaction,
  getNSEProductCode
} from "../apisMethods";
import TransactSuccess from "./transaction-successforRedumption";
import SWW from "./something-went-wrong";
import { globalInfo } from "../App";
const Redemtion_Popup = ({ show, setShow, data }) => {
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [amount, setAmount] = useState("");
  const [switchType, setSwitchType] = useState("Amount")
  const [headerState, setHeaderState] = useState({})
  const [useProfileData, setUseProfileData] = useState({})


  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    Object.keys(data).length > 0 && userProfileDataCall()
  }, [data])
  const userProfileDataCall = async () => {
    let reqBodyForIin = {
      folio: data?.folio,
      productcode: data?.productcode,
      rta: data?.rta,
      gpan: data?.gpan ? data?.gpan : ""
    }

    let res = await getUserProfileViaPortfolio(reqBodyForIin, headerState)

    if (!res.data) {
      return
    } else {
      setUseProfileData(res.data?.data)
    }
  }
  const amountHandler = (e) => {
    if (switchType === "Y") {
      setAmount(data?.unit)
      return
    }
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    setAmount(sanitizedValue);
  };

  const orderRedumption = async () => {
    let checkAmount = Number(amount)
    if (!amount) {
      notify("error", "please enter amount or unit");
      return
    }

    if (checkAmount===0 && switchType === "Amount") {
      notify("error", "Entered Amount should not zero");
      return
    }
    
    
    if ((switchType === "Unit" || switchType === "Y") && checkAmount == 0) {
      notify("error", "Unit should not be less than 1.");
      return
    }
    if (checkAmount > data?.unit && switchType === "Unit") {
      notify("error", "Entered units should not be greater than the available units.");
      return;
    }
    if (
      checkAmount > Number(data?.currentvalue) &&
      switchType === "Amount"
    ) {
      notify("error", "Entered amount should not be greater than the available amount.");
      return;
    }

    let nseData = {}
    setLoader('block')
    const nseRes = await getNSEProductCode({ schemecode: useProfileData?.schemecode }, headerState)
    if (!nseRes.data) {
      setLoader('none')
      notify("warn", "nse product code not found please try again")
      return
    } else {
      nseData = {
        productcode: nseRes.data?.data?.productcode,
        reinvestTag: nseRes.data?.data?.REINVEST_TAG,
      }
    }
    const reqBody = {
      email: useProfileData?.email,
      tax_status: useProfileData?.taxstatus,
      trxn_acceptance: "ALL", // Static
      amc: useProfileData?.amccode,
      product_code: nseData?.productcode,
      amt_unit: amount,
      remark: "",
      folio: data.folio,
      amt_unit_type: switchType === "Y" || switchType === "Unit" ? "Unit" : "Amount",
      all_units: switchType !== "Y" ? "N" : "Y",
      input_ref_no: "",
      iin: useProfileData.iin,
    };

    redeem(reqBody, headerState).then((res) => {

      if (res.data?.status === 200) {

        let nseData = {
          Unique_No: res.data?.data?.Unique_No,
          Trxn_No: res.data?.data?.Trxn_No,
          Application_No: res.data?.data?.Application_No,
          Fund: res.data?.data?.Fund,
          Scheme: res.data?.data?.Scheme,
          Scheme_Name: res.data?.data?.Scheme_Name,
          Amt: res.data?.data?.Amt,
          Status_Desc: res.data?.data?.Status_Desc,
          Status_code: res.data?.data?.Status_code,
          Folio: res.data?.data?.Folio,
          Platform: "Web",
          transaction_type: "Redemption",
          iin: useProfileData?.iin,
          Pan: useProfileData?.pan,
          Gpan: useProfileData?.gpan,
          Joint1pan: useProfileData?.Joint1pan,
          Joint2pan: useProfileData?.Joint2pan,
          name: useProfileData?.name,
        };
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        setConfirmationDataArr([]);
        setAmount('')
        setLoader('none')
        saveNseTransaction(nseData, headerState).then((res) => {
        });
        if (res.data?.data?.Paymentlink) {
          window.open(res.data?.data?.Paymentlink);
        }
      } else {
        setLoader('none')
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }


    });
  }

  return (
    <>
      <Modal show={show} onHide={() =>
        setShow(false)} scrollable={true} className="animate__animated animate__zoomIn animate__fast">
        <Modal.Header closeButton>
          <Modal.Title>Redemption</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='text-red'>{data?.name}</h5>
          {/* <div className="col-3 bg-light-red text-center rounded">
            <p className='py-1 '>{data.assettype}</p>
          </div> */}
          <p className='fs-13 text-red'>{data.scheme}({data.folio})</p>
          <Form className="portfolio">

            <Form.Group className=" " controlId="formBasicRadio">
              <Form.Label>
                Redemption Type <span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="By Amount"
                  name="formHorizontalRadios"
                  id="ByAmount"
                  value={"Amount"}
                  onChange={(e) => {
                    setAmount("");
                    setSwitchType(e.target.value);
                  }}
                  checked={switchType == "Amount"}
                  className="pe-lg-3 pe-2 fs-sm-14"
                />
                <Form.Check
                  type="radio"
                  label="By Units"
                  value={"Unit"}
                  onChange={(e) => {
                    setAmount("");
                    setSwitchType(e.target.value);
                  }}
                  checked={switchType == "Unit"}
                  name="formHorizontalRadios"
                  id="ByUnits"
                  className="pe-lg-3 pe-2 fs-sm-14"
                />
                <Form.Check
                  type="radio"
                  label="All Units"
                  name="formHorizontalRadios"
                  value={"Y"}
                  onChange={(e) => {
                    setAmount(data?.unit);
                    setSwitchType(e.target.value);
                  }}
                  checked={switchType == "Y"}
                  id="AllUnits"
                  className="pe-lg-3 pe-2 fs-sm-14"
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label className="d-flex justify-content-between">Enter Amount
                {/* <small className="color-small"> Min Amount : 1000</small> */}
              </Form.Label>
              <Form.Control type="text" placeholder={switchType === "Y" ? data.unit : switchType} className="border-0 single-drop" value={amount} onChange={amountHandler} />
              <Form.Text className="text-muted">
                Total Amount : {data?.currentvalue} and Total unit : {data?.unit} As On : {new Date(data?.currentDate).toLocaleDateString()}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="danger" onClick={orderRedumption} >
            Order
          </Button>
        </Modal.Footer>
      </Modal>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
    </>
  )
}
export default Redemtion_Popup;