import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import SWW from "./something-went-wrong";
import TransactSuccess from "./transact-success";
import { notify } from "../pages/toastCreater";
import {
  amcByProfile,
  folioViaAmc,
  schemeViaFolio,
  getNSEProductCode,
  getTransactionByFolioProductcode,
  switchTransact,
  saveNseTransaction,
} from "../apisMethods";
import { globalInfo } from "../App";

const SwitchTransact = ({ show, setShow, amcList, userProfileData }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [amc, setAmc] = useState("");
  const [folio, setFolio] = useState("");
  const [folioList, setFolioList] = useState([]);

  const [profileName, setProfileName] = useState("");
  const [tempProfileName, setTempProfileName] = useState({});
  const [scheme, setScheme] = useState("");
  const [assetClass, setAssetClass] = useState("1");
  const [switchType, setSwitchType] = useState("Amount");
  const [option, setOption] = useState("1");
  const [targetScheme, setTargetScheme] = useState("");
  const [tempTargetScheme, setTempTargetScheme] = useState({});
  const [amount, setAmount] = useState("");
  const [amcListData, setAmcListData] = useState([]);
  const [headerState, setHeaderState] = useState({});
  const [tempFolio, setTempFolio] = useState({});
  const [schemeDataArr, setSchemeDataArr] = useState([]);
  const [targetSchemeDataArr, setTargetSchemeDataArr] = useState([]);
  const [allUnitsData, setAllUnitsData] = useState({});
  const [nseProductCode, setNseProductCode] = useState("");
  const [targetNseData, setTargetNseData] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);

  //loader
  const [amcLoader, setAmcLoader] = useState(false);
  const [folioLoading, setFolioLoading] = useState(false);
  const [schemeLoader, setSchemeLoader] = useState(false);
  const [targetSchemeLoader, setTargetSchemeLoader] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
  }, []);


  const amcChangeHandler = (e) => {
    const value = e.target.value;

    setAmc(value);

    if (value) {
      setFolio('')
      setTempFolio({})
      setScheme('')
      setTargetScheme('')
      setTempTargetScheme({})
      setTargetSchemeDataArr([])
      setSchemeDataArr([])
      setAllUnitsData({})
      setFolioLoading(true);
      let joint1stPan = "";
      let joint2ndPan = "";

      if (tempProfileName?.JOINT1_PAN) {
        joint1stPan = tempProfileName?.JOINT1_PAN;
      } else if (tempProfileName?.JOINT1_VALIDPAN?.length > 1) {
        joint1stPan = tempProfileName?.JOINT1_VALIDPAN;
      } else {
        joint1stPan = "";
      }
      if (tempProfileName?.JOINT2_PAN) {
        joint2ndPan = tempProfileName?.JOINT2_PAN;
      } else if (tempProfileName?.JOINT2_VALIDPAN?.length == 10) {
        joint2ndPan = tempProfileName?.JOINT2_VALIDPAN;
      } else {
        joint2ndPan = "";
      }
      const reqBody = {
        investor_pan: tempProfileName?.pan,
        jh1_pan: joint1stPan,
        jh2_pan: joint2ndPan,
        amccode: value,
        gpan: tempProfileName?.gpan ? tempProfileName?.gpan  : "" ,
      };
      folioViaAmc(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("error", res.error?.response?.data?.msg);
          setFolioLoading(false);
        } else {
          setFolioList(res.data?.data);
          setFolioLoading(false);
        }
      });
    } else {
      setFolioList([]);
    }
  };



  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      setAmcLoader(true);
      let reqData = JSON.parse(e.target?.value);
      refreshState()
      setProfileName(e.target?.value);
      setTempProfileName(reqData);
      amcByProfileCall(reqData);
    }
  };

  const refreshState = () => {
    setAmc('')
    setFolio('')
    setTempFolio({})
    setScheme('')
    setAmount('')
    setTargetScheme('')
    setTempTargetScheme({})
    setSchemeDataArr([])
    setTargetSchemeDataArr([])
    setFolioList([])
    setAllUnitsData({})

  }
  const amcByProfileCall = (reqData) => {
    let joint1stPan = "";
    let joint2ndPan = "";

    if (reqData?.JOINT1_PAN) {
      joint1stPan = reqData?.JOINT1_PAN;
    } else if (reqData?.JOINT1_VALIDPAN?.length === 10) {
      joint1stPan = reqData?.JOINT1_VALIDPAN;
    } else {
      joint1stPan = "";
    }
    if (reqData?.JOINT2_PAN) {
      joint2ndPan = reqData?.JOINT2_PAN;
    } else if (reqData?.JOINT2_VALIDPAN?.length === 10) {
      joint2ndPan = reqData?.JOINT2_VALIDPAN;
    } else {
      joint2ndPan = "";
    }
    console.log("reqData",reqData);

    const reqBody = {
      investor_pan: reqData?.pan,
      jh1_pan: joint1stPan,
      jh2_pan: joint2ndPan,
      gpan: reqData?.gpan ? reqData?.gpan:"" 
    };
    amcByProfile(reqBody, headerState).then((res) => {
      setAmcListData(res.data?.data);
      setAmcLoader(false);
    });
  };

  const changeFolioHandler = (e) => {
    if (e.target.value) {
      setScheme('')
      setTargetScheme('')
      setTempTargetScheme({})
      setTargetSchemeDataArr([])
      setAllUnitsData({})
      setSchemeLoader(true);
      let jsonData = JSON.parse(e.target?.value);
      setFolio(e.target.value);
      setTempFolio(jsonData);
      const reqBody = {
        amc_code: amc,
        myscheme: true,
        folio: jsonData?.folio,
        rta: jsonData?.rta,
        isAssetOptCodeAvailable: false
      };
      schemeViaFolio(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("warn", "this scheme is not available")
          setTargetSchemeDataArr([])
          setSchemeDataArr([])
        } else {
          setSchemeDataArr(res.data?.data)
        }
        setSchemeLoader(false)

      });
    }
  };
  const assetClasHandler = (e) => {
    setAssetClass(e.target.value);

    if (!amc || !tempFolio?.folio) {
      setSchemeDataArr([])
      setTargetScheme({})
      setTempTargetScheme('')
      setScheme('')
      setTargetSchemeDataArr([])
      return
    }

    setTargetSchemeLoader(true);
    const reqBody = {
      amc_code: amc,
      myscheme: false,
      folio: tempFolio?.folio,
      rta: tempFolio?.rta,
      opt_code: Number(option),
      asset_code: Number(e.target.value),
      isAssetOptCodeAvailable: true
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        notify("warn", "this scheme is not available")
      } else {
        setTargetSchemeDataArr(res.data?.data)
      }
      setTargetSchemeLoader(false)
    });
  };

  const optionHandler = (e) => {

    setOption(e.target.value);
    if (!amc || !tempFolio?.folio) {
      setTargetSchemeDataArr([])
      setScheme('')
      setTempTargetScheme('')
      setTargetScheme('')
      setSchemeDataArr([])
      return
    }
    setTargetSchemeLoader(true);

    const reqBody = {
      amc_code: amc,
      myscheme: false,
      folio: tempFolio?.folio,
      rta: tempFolio?.rta,
      opt_code: Number(e.target?.value),
      asset_code: Number(assetClass),
      isAssetOptCodeAvailable: true
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        notify("error", "Something went wrong")
        setTargetSchemeDataArr([]);
      } else {
        setTargetSchemeDataArr(res.data?.data);
      }
      setTargetSchemeLoader(false);

    });
  };

  const changeSchemeHandler = (e) => {
    if (e.target.value) {
      if (!tempFolio?.folio || !amc) {
        return
      }
      setTargetScheme("")
      setTempTargetScheme({})
      let schemeData = JSON.parse(e.target?.value);
      let reqBody = {
        schemecode: Number(schemeData.schemecode),
      };
      setScheme(e.target.value);
      getNSEProductCode(reqBody, headerState).then((res) => {
        setNseProductCode(res.data?.data?.productcode);
        let data = { folio: tempFolio?.folio, productcode: schemeData?.productCode };
        getTransactionByFolioProductcode(data, headerState).then((res) => {
          if (!res.data) {
            notify("warn", "Please select another Scheme")
          } else {
            setAllUnitsData(res.data?.data);
            if (res.data?.data?.unit === 0) {
              notify("warn", "You don't have sufficient units in this folio")
            } else {
              if (switchType === "Y") {
                setAmount(res.data?.data?.unit);
              }
            }
          }
        });
      });
      const reqBodyTarget = {
        amc_code: amc,
        myscheme: false,
        folio: tempFolio?.folio,
        rta: tempFolio?.rta,
        opt_code: Number(option),
        asset_code: Number(assetClass),
        isAssetOptCodeAvailable: true
      };
      schemeViaFolio(reqBodyTarget, headerState).then((res) => {
        const filteredArray = res.data?.data.filter(item => item.productCode !== schemeData?.productCode);
        setTargetSchemeDataArr(filteredArray);
      });
    }
  };
  const amountHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    setAmount(sanitizedValue);
  };

  const changeTargetSchemehandler = (e) => {
    let targetData = JSON.parse(e.target.value);
    setTempTargetScheme(targetData)
    let reqBody = {
      schemecode: Number(targetData?.schemecode),
    };
    setTargetScheme(e.target.value);
    getNSEProductCode(reqBody, headerState).then((res) => {
      if (!res.data) {
        setTargetScheme("")
        setTempTargetScheme({})
        notify("warn", "This scheme is not available please select another")
      } else {
        setTargetNseData({
          productcode: res.data?.data?.productcode,
          reinvestTag: res.data?.data?.REINVEST_TAG,
        });
      }

    });
  };

  const orderSwitch = () => {
    let checkAmount = Number(amount);
    if (
      !folio ||
      !amc ||
      !profileName ||
      !scheme ||
      !targetScheme
    ) {
      notify("error", "All Fields Required.");
      return;
    }
    if(!amount && switchType === "Amount"){
      notify("warn", "Plaese enter amount");
      return;
    }
    if(!amount && switchType === "Unit"){
      notify("warn", "Plaese enter unit");
      return;
    }
    if (checkAmount > allUnitsData?.unit && switchType === "Unit") {
      notify("warn", "Entered units should not be greater than the available units");
      return;
    }
    if (
      checkAmount > Number(allUnitsData?.currentvalue) &&
      switchType === "Amount"
    ) {
      notify("warn", "Entered amount should not be greater than the available amount.");
      return;
    }
    if (allUnitsData?.currentvalue === 0) {
      notify("error", "You don't have sufficient units in this folio");
      return
    }

    const reqBody = {
      email: tempProfileName?.email,
      tax_status: tempProfileName?.taxStatus,
      trxn_acceptance: "ALL",
      amc: amc,
      product_code: nseProductCode,
      amt_unit: amount,
      remark: "",
      folio: tempFolio?.folio,
      amt_unit_type: switchType === "Y" ? "Unit" : "Amount",
      all_units: switchType !== "Y" ? "N" : "Y",
      //Static
      input_ref_no: "",
      //Static
      trxn_execution: "",
      target_product_code: targetNseData?.productcode,
      //Static
      target_ft_acc_no: "",
      //reinvest flag of target scheme
      reinvest: targetNseData?.reinvestTag,
      iin: tempProfileName?.IIN,
    };
    setLoader("block")
    switchTransact(reqBody, headerState).then((res) => {
     
      if (res.data?.success === true) {
        let nseData = {
          Unique_No: res.data?.data?.Unique_No,
          Trxn_No: res.data?.data?.Trxn_No,
          Application_No: res.data?.data?.Application_No,
          Fund: res.data?.data?.Fund,
          Target_Scheme_Name: res.data?.data?.Target_Scheme_Name,
          Source_Scheme_Name: res.data?.data?.Source_Scheme_Name,
          Target_Scheme: res.data?.data?.Target_Scheme,
          Amt: res.data?.data?.Amt,
          Status_Desc: res.data?.data?.Status_Desc,
          Status_code: res.data?.data?.Status_code,
          Folio: res.data?.data?.Folio,
          Platform: "Web",
          transaction_type: "Switch",
          iin: tempProfileName?.IIN,
          Pan: tempProfileName?.pan,
          Gpan: tempProfileName?.gpan,
          Joint1pan: tempProfileName?.JOINT1_PAN,
          Joint2pan: tempProfileName?.JOINT2_PAN,
          name: tempProfileName?.name,
        };
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        setConfirmationDataArr([]);
        saveNseTransaction(nseData, headerState).then((res) => {
          setLoader("none")
          refreshState()
          setProfileName('')
          setTempProfileName({})
        });
      } else {
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }
      setLoader("none")
    });
  };

  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          className="invest-gray "
          scrollable={true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">Switch</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={profileChangeHandler}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                          <option
                            value={JSON.stringify({
                              email: el.EMAIL,
                              taxStatus: el.TAX_STATUS,
                              IIN: el.IIN,
                              name: el.NAME,
                              pan: el.PAN,
                              gpan: el?.GUARD_PAN,
                              jtname1: el.JOINT_NAME1,
                              jtname2: el.JOINT_NAME2,
                              nominee: el.NOM_NAME,
                              JOINT1_PAN: el.JOINT1_PAN,
                              JOINT2_PAN: el.JOINT2_PAN,
                              JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                              JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                            })}
                            key={el.PAN}
                          >
                            {`${el.NAME} ${el.JOINT_NAME1 && '|'} ${el.JOINT_NAME1}`}
                          </option>
                        ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      placeholder="Select AMC"
                      onChange={amcChangeHandler}
                      value={amc}
                      disabled={amcLoader}
                    >
                      <option value="">Select AMC</option>
                      {amcListData &&
                        amcListData.map((el) => (
                          <option value={el.amccode} key={el.amccode}>
                            {el.amc}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Folio <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={folio}
                      onChange={changeFolioHandler}
                      disabled={folioLoading}
                    >
                      <option value={""}>Select folio</option>
                      {folioList.length > 0 &&
                        folioList.map((el) => (
                          <option
                            value={JSON.stringify({
                              folio: el.folio,
                              accord_schemecode: el.accord_schemecode,
                              rta: el.rta,
                            })}
                            key={el.PAN}
                          >
                            {el.folio}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>
                      Select Source Scheme
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={scheme}
                      onChange={changeSchemeHandler}
                      disabled={schemeLoader}
                    >
                      <option value={""}>Select Scheme</option>
                      {schemeDataArr.length > 0 &&
                        schemeDataArr.map((el) => (
                          <option value={JSON.stringify({
                            schemecode: el?.schemecode, productCode: el.productCode
                          })}
                            key={el?.schemecode}>
                            {el?.shortName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  {allUnitsData?.unit >= 0 ? (
                    <small className="text-red pull-left">
                      <i>
                        {`Available Units: ${allUnitsData?.unit},     Total Amount: Rs ${allUnitsData?.currentvalue} `}
                      </i>
                    </small>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Asset Class <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Equity"
                        name="assetClass"
                        value={"1"}
                        id="equity"
                        checked={assetClass === "1"}
                        onChange={assetClasHandler}
                        className="pe-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Debt"
                        name="assetClass"
                        value={"3"}
                        checked={assetClass === "3"}
                        onChange={assetClasHandler}
                        id="debt"
                        className="pe-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Other"
                        name="assetClass"
                        value={"2"}
                        checked={assetClass === "2"}
                        onChange={assetClasHandler}
                        id="other"
                        className="pe-3"
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-2">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Option <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Growth"
                        name="options"
                        value={1}
                        onChange={optionHandler}
                        checked={option == 1}
                        id="Growth"
                        className="pe-3"
                      />
                      <Form.Check
                        type="radio"
                        label="IDCW"
                        name="options"
                        value={2}
                        onChange={optionHandler}
                        checked={option == 2}
                        id="IDCW"
                        className="pe-3"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>
                      Select Target Scheme
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={targetScheme}
                      disabled={targetSchemeLoader}
                      onChange={changeTargetSchemehandler}
                    >
                      <option value={""}>Select Target Scheme</option>
                      {targetSchemeDataArr.length > 0 &&
                        targetSchemeDataArr.map((el) => (
                          <option value={JSON.stringify({
                            schemecode: el?.schemecode, minimumAmount: el.minswitchamount, minimumUnit: el.minswitchunits
                          })}>
                            {el.shortName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-7">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Redemption Type <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="By Amount"
                        name="formHorizontalRadios"
                        id="ByAmount"
                        value={"Amount"}
                        onChange={(e) => {
                          setAmount("");
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Amount"}
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                      <Form.Check
                        type="radio"
                        label="By Units"
                        value={"Unit"}
                        onChange={(e) => {
                          setAmount("");
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Unit"}
                        name="formHorizontalRadios"
                        id="ByUnits"
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                      <Form.Check
                        type="radio"
                        label="All Units"
                        name="formHorizontalRadios"
                        value={"Y"}
                        onChange={(e) => {
                          setAmount(allUnitsData?.unit);
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Y"}
                        id="AllUnits"
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>
                      Enter   {switchType == "Y" ? "All Units" : switchType} <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder={switchType == "Y" ? "All Units" : switchType}
                      value={amount}
                      onChange={amountHandler}
                    />
                    {/* {switchType == "Unit"?<small className="text-label float-right">
                      Min Unit : {tempTargetScheme?.minimumUnit ? tempTargetScheme?.minimumUnit:1}
                    </small>:  <small className="text-label float-right">
                      Min Amount : {tempTargetScheme?.minimumAmount ? tempTargetScheme?.minimumAmount:500}
                    </small>} */}

                  </Form.Group>
                </div>
              </div>
             
            </Form>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button className="btn-custom" type="button" onClick={orderSwitch}>
              Order Now
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
    </>
  );
};
export default SwitchTransact;
