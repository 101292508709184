import React, { useState,useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { connectRm, rateRm } from "../apisMethods";
import { notify } from "../pages/toastCreater";
import { globalInfo } from "../App";

const RatingPopUp = ({ show, setShow }) => {
  const { setLoader } = useContext(globalInfo);
  const [ratingNumber, setRatingNumber] = useState(0);
  const [comment, setComment] = useState("");

  // Catch Rating value
  const handleRating = (rate) => {
    setRatingNumber(rate);
  };
  const submitRating = async () => {
    if(ratingNumber<=0){
      notify("error","Please select rating..")
      return
    }
    if(!comment){
      notify("error","Please enter comment..")
      return
    }
    let userData = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setLoader("block");
    connectRm({ userEmail: userData?.email ? userData?.email : "" }).then(
      (res) => {
        if (res.data) {
         rateRm({_id:res.data?.id,rating:ratingNumber,msg:comment?.trim()},header).then((res)=>{
          if(res.data){
            setLoader("none");
            setShow(false)
            setComment("")
            setRatingNumber(0)
            notify("success",res.data?.msg)
          }else{
            notify("error","Something went wrong")
            setLoader("none");
          }
         })
        } else {
          setLoader("none");
          console.log("errror");
        }
      }
    );
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="login"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-600">Give a Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container row justify-content-center">
            <div className="col-md-12 row text-center">
              <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                allowFraction={true}
                fillColor="#f06d70"
                size={35}
                /* Available Props */
              />
              <div className="form-group">
                {/* <label for="exampleFormControlTextarea1">Comment</label> */}
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Type here..."
                  rows="7"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-custom" onClick={submitRating}>
            Send a Review
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RatingPopUp;
