import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import icon from "../assets/images/nfpicon.png";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from "react-router-dom";
import NfoInvestNow from "../components/nfo-investnow";
import { apis } from "../request";
import { formatDateString } from "../currentMonth/FinancialYear";
import LumpsumForm from "../components/lumpsum-form";
import { useCount } from "./helpers/CartCountProvide";
import SIP_Form from "../components/sip-form";


const NofDetail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { cartData, updateCart, addToCart } = useCount();


  const [lumpsumformShow, setLumpsumformShow] = useState(false)
  const [sipformShow, setSipformShow] = useState(false)
  const [selectedScheme, setSelectedScheme] = useState({})
  const [singleNfoDetails, setSingleNfoDetails] = useState({})
  const [checkRemaining, setCheckRemaining] = useState()


  useEffect(() => {
    if (!location.state) {
      navigate("/")
    }
    setSingleNfoDetails(location.state)
    handleRemaining()
  }, [])
  const handleRemaining = () => {
    const date1 = new Date();
    const date2 = new Date(location.state?.nfo_close);
    const differenceInMilliseconds = date2 - date1;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = (differenceInMilliseconds / millisecondsPerDay).toFixed(0);
    setCheckRemaining(differenceInDays)
    console.log(differenceInDays);
  }

  const investInLumpsun = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    if (user?.iin) {
      setSelectedScheme([location.state]);
      setLumpsumformShow(true)
    } else if (token) {
      navigate("/dashboard/Profile-creation")
    } else {
      navigate("/")
    }
  }
  const investInSip = (itm) => {
    const user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    if (user?.iin) {
      location.state["fromNfo"] =true
      setSelectedScheme([location.state]);
      setSipformShow(true)

    } else if (token) {
      navigate("/dashboard/Profile-creation")
    } else {
      navigate("/")
    }
  }

  return (
    <>
      <div className="wrapper nfo-detail">
        <div className="report px-lg-5 ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                NFO Detail
              </li>
            </ol>
          </nav>
        </div>

        <section className="nfo-detail pt-3">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-10 col-12">
                <div className="p-4 shadow-custom text-left m-sm-auto">
                  <div className="table-responsive">
                    <Table >
                      <thead className="align-baseline">
                        <tr>
                          {/* <td className='px-lg-4 wd-7rem'></td> */}
                          <td className='px-lg-4 wd-20rem text-center'><img src={`${apis.imageLink}${singleNfoDetails?.AMC_CODE}.png`} alt="" className='img-fluid  mx-4em  br-50 me-3' />{singleNfoDetails?.PRODUCT_LONG_NAME}
                          </td>
                          {/* <td className=' wd-15rem'>Very High risk - Other - Index</td> */}
                        </tr>
                      </thead>
                    </Table>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-8 py-4 table-responsive">
                      <Table>
                        <tbody>
                          <tr className="text-label">
                            <td className="wd-8rem">Launch date
                            </td>
                            <td className="wd-11rem">End Date
                            </td>
                            {/* <td className="wd-11rem">Allotment date
      </td> */}
                            <td>NAV
                            </td>
                          </tr>
                          <tr>
                            <td className='pt-3'>{formatDateString(singleNfoDetails?.nfo_open)}</td>
                            <td className='pt-3'>{formatDateString(singleNfoDetails?.nfo_close)}</td>
                            {/* <td className='pt-3'>2nd Sept 2022</td> */}
                            <td className='pt-3'>₹ {singleNfoDetails?.offerprice}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {checkRemaining == 0 ? <div className="row justify-content-center pt-2">
                    <div className="col-md-3 pb-3">
                      <h6 className="py-2 nfo-alert "><img src={icon} alt="icon" /><span className="ps-4">NFO will close today.</span></h6>
                    </div>
                  </div> : <div className="row justify-content-center pt-2">
                    <div className="col-md-5 pb-3">
                      <h6 className="py-2 nfo-alert "><img src={icon} alt="icon" /><span className="ps-4">NFO will close in {checkRemaining} days.</span></h6>
                    </div>
                  </div>}


                 

                  <div className=" py-4 row justify-content-center mt-5">
                    <div className="col-6 col-md-4 col-lg-2 ">
                      <div onClick={() => investInLumpsun()} className='btn-custom fs-sm-13 m-2' type="button">LUMPSUM</div>
                    </div>
                    {location?.state.SIP_ALLOWED === "Y" ? <div className="col-6 col-md-4 col-lg-2"> <div onClick={() => investInSip()} className='btn-custom fs-sm-13 m-2 w-100 px-1' type="button">SIP</div>   </div> : ""}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <LumpsumForm
          show={lumpsumformShow}
          setShow={setLumpsumformShow}
          rightSchemes={selectedScheme}
        />
        <SIP_Form show={sipformShow} setShow={setSipformShow} sipSchemes={selectedScheme} />

      </div>
    </>

  )
}
export default NofDetail