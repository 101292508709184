import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Video_blog from './video-blog';
import { apis } from '../request';
import PortfolioContent from './portfolio-content';
const UnderwatchConfirm = ({ show, setShow, underWatchDataData }) => {

  const content = " <p>Bravo! That was a praiseworthy initiative given the level of financial literacy and the lack of interestin investors to know about it. We have to hand it to you that you are one of those curious and courageous investor.</p> <p>We provide suggestion of under watch for the following reasons:</p> <p>1. The scheme under remark “under watch” does not follow our Algo because either the scheme is new or we do not have the required data. Statistically, it is believed that the larger will be the data,the more accurate will be the results. And since we judge the performance of a scheme on the basis of consistency than on “one time show”, we need a large amount of data to analyse a particular scheme. Though, schemes under watch do not have that amount of data, yet we have conviction on the fund management methodology of the scheme/AMC.</p><p>The scheme categorisation was changed due to change in categorization policies of SEBI. That is, for example, a scheme was earlier categorised as a large cap scheme but due to new policies of categorisation the AMC changed the scheme categorisation as Large and mid-cap scheme. In this case the earlier data of the scheme became irrelevant now.</p> <p>Nonetheless, please rest assured. We got you covered on this and closely watching the performance and other characteristics of these schemes. so, you can relax.</p> <p>PS: Sometimes we find the rising stars amongst schemes under the remarks- “Under Watch”</p> <p>Thanking you again for reading this….</p>  <p>We might sound greedy….</p>"

  const heading="Under Watch"
  const [isPortfolioContentShow, setIsPortfolioContentShow] = useState(false)
  const [Videoblogshow, setVideoblogShow] = useState(false);
  
  
 

  const openInnerModal = () => {
    setVideoblogShow(true);
    setShow(false)
  }

  const closeInnerModal = () => {
    setVideoblogShow(false);
    setShow(true)
  }

  const showPortfolioContent = ()=>{
   setIsPortfolioContentShow(true)
  }
  return (
    <>
      <div >
        <Modal className='rounded' show={show} onHide={() =>
          setShow(false)} centered scrollable={true}>

          <Modal.Header closeButton >
            <Modal.Title className='fs-6'>Underwatch</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3">
            <h6 className='text-black fs-16'>Schemes Name</h6>
            <div class="col-md-12">
              {underWatchDataData?.map((item) => {
                return <div className=' d-flex  shadowcart mb-2 p-3'>
                  <div><img src={`${apis.imageLink}${item?.amc_code}.png`} alt="" className='img-fluid mx-3em ' /></div>
                  <div className=' px-2 fs-14 align-self-center'>{item.s_name}
                  </div>

                </div>
              })}

            </div>


          </Modal.Body>
          <Modal.Footer className='border-0'>
            <p className='fs-10 text-justify'>Though not meeting the criteria of our Algorithm “Prodigy” precisely, the mentioned
              schemes are there in our watch list nonetheless as our research team is keeping a close
              eye on their performances. <span className='text-red crPointer' onClick={showPortfolioContent}>Read more..</span></p>
              <p className='fs-10 text-justify'>Suggested Action: Remain invested. </p>

          </Modal.Footer>


        </Modal>
      </div>



      <Video_blog show={Videoblogshow} setShow={closeInnerModal} />
      <PortfolioContent show={isPortfolioContentShow} setShow={setIsPortfolioContentShow} content={content} heading ={heading}/>
    </>
  )
}
export default UnderwatchConfirm