import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Cart_Delete from "./delete-confirmation";
import Form from "react-bootstrap/Form";
import TransactFailed from "./transaction-failed";
import SWW from "./something-went-wrong";
import TransactSuccess from "./transaction-successforRedumption";
import { notify } from "../pages/toastCreater";
import { globalInfo } from "../App";
import {
  amcByProfile,
  folioViaAmc,
  getNSEProductCode,
  getTransactionByFolioProductcode,
  saveNseTransaction,
  schemeViaFolio,
  swpTransact,
} from "../apisMethods";

const SWPTransact = ({ show, setShow, amcList, userProfileData }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [amc, setAmc] = useState("");
  const [folio, setFolio] = useState("");
  const [folioList, setFolioList] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [tempProfileName, setTempProfileName] = useState({});
  const [scheme, setScheme] = useState("");
  const [tempScheme, setTempScheme] = useState({});
  const [schemeType, setSchemeType] = useState("true");
  const [switchType, setSwitchType] = useState("Amount");
  const [amount, setAmount] = useState();
  const [amcListData, setAmcListData] = useState([]);
  const [headerState, setHeaderState] = useState({});
  const [tempFolio, setTempFolio] = useState({});
  const [nseProductCode, setNseProductCode] = useState("");
  const [schemeDataArr, setSchemeDataArr] = useState([]);
  const [allUnitsData, setAllUnitsData] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [swpFrom, setSwpFrom] = useState("");
  const [swpTo, setSwpTo] = useState("");
  const [swpAvailabledate, setSwpAvailabledate] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [frequency, setFrequency] = useState("");
  const [addSwpDeatlais, setAddSwpDeatlais] = useState([]);
  const [swpAllDetails, setSwpAllDetails] = useState({});
  const [amcLoader, setAmcLoader] = useState(false);
  const [folioLoading, setFolioLoading] = useState(false);
  const [schemeLoader, setSchemeLoader] = useState(false);
  const [targetSchemeLoader, setTargetSchemeLoader] = useState(false);

  const refreshForProfile = () => {
    setAmcListData([]);
    setFolioList([]);
    setSchemeDataArr([]);
    setSwpFrom("");
    setSwpTo("");
    setAmc("")
    setFrequency("");
    setAmount("");
    setFolio('')
    setTempFolio({})
    setTempScheme({})
    setScheme('')
    setAllUnitsData({})
    setSwpAllDetails({});
  };

  const refreshForAmc = () => {
    setFolioList([]);
    setSchemeDataArr([]);
    setSwpFrom("");
    setSwpTo("");
    setFolio('')
    setTempFolio({})
    setTempScheme({})
    setScheme('')
    setFrequency("");
    setAmount("");
    setSwpAllDetails({});
    setAllUnitsData({})
  };

  const refreshForFolio = () => {
    setSchemeDataArr([]);
    setSwpFrom("");
    setSwpTo("");
    setFrequency("");
    setAmount("");
    setSwpAllDetails({});
    setAllUnitsData({})
    setScheme('')
    setTempScheme({})
  };

  const refreshForScheme = () => {
    setSwpFrom("");
    setSwpTo("");
    setFrequency("");
    setAmount("");
    setSwpAllDetails({});
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth() + 1;
    let currentDay = date.getDate();
    if (currentDay >= 10 && currentMonth >= 10) {
      setTodayDate(`${currentYear}-${currentMonth}-${currentDay}`);
    } else if (currentDay < 10 && currentMonth < 10) {
      setTodayDate(`${currentYear}-0${currentMonth}-0${currentDay}`);
    } else if (currentDay < 10) {
      setTodayDate(`${currentYear}-${currentMonth}-0${currentDay}`);
    } else if (currentMonth < 10) {
      setTodayDate(`${currentYear}-0${currentMonth}-${currentDay}`);
    }
  }, []);

  const amcChangeHandler = (e) => {
    const value = e.target.value;

    setAmc(value);
    refreshForAmc();
    if (value) {
      setFolioLoading(true);
      let joint1stPan = "";
      let joint2ndPan = "";

      if (tempProfileName?.JOINT1_PAN) {
        joint1stPan = tempProfileName?.JOINT1_PAN;
      } else if (tempProfileName?.JOINT1_VALIDPAN?.length > 1) {
        joint1stPan = tempProfileName?.JOINT1_VALIDPAN;
      } else {
        joint1stPan = "";
      }
      if (tempProfileName?.JOINT2_PAN) {
        joint2ndPan = tempProfileName?.JOINT2_PAN;
      } else if (tempProfileName?.JOINT2_VALIDPAN?.length == 10) {
        joint2ndPan = tempProfileName?.JOINT2_VALIDPAN;
      } else {
        joint2ndPan = "";
      }
      const reqBody = {
        investor_pan: tempProfileName?.pan,
        jh1_pan: joint1stPan,
        jh2_pan: joint2ndPan,
        amccode: value,
        gpan: tempProfileName?.gpan ? tempProfileName?.gpan:"",
      };
      folioViaAmc(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("error", res.error.response?.data?.msg);
          setFolioLoading(false);
        } else {
          setFolioList(res.data?.data);
          setFolioLoading(false);
        }
      });
    } else {
      setFolioList([]);
    }
  };

  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      refreshForProfile();
      setAmcLoader(true);
      let reqData = JSON.parse(e.target?.value);
      setProfileName(e.target?.value);
      setTempProfileName(reqData);
      amcByProfileCall(reqData);
    }
  };

  const amcByProfileCall = (reqData) => {
    let joint1stPan = "";
    let joint2ndPan = "";

    if (reqData?.JOINT1_PAN) {
      joint1stPan = reqData?.JOINT1_PAN;
    } else if (reqData?.JOINT1_VALIDPAN?.length === 10) {
      joint1stPan = reqData?.JOINT1_VALIDPAN;
    } else {
      joint1stPan = "";
    }
    if (reqData?.JOINT2_PAN) {
      joint2ndPan = reqData?.JOINT2_PAN;
    } else if (reqData?.JOINT2_VALIDPAN?.length === 10) {
      joint2ndPan = reqData?.JOINT2_VALIDPAN;
    } else {
      joint2ndPan = "";
    }
    const reqBody = {
      investor_pan: reqData?.pan,
      jh1_pan: joint1stPan,
      jh2_pan: joint2ndPan,
      gpan: reqData?.gpan ? reqData?.gpan :"",
    };
    amcByProfile(reqBody, headerState).then((res) => {
      setAmcListData(res.data?.data);
      setAmcLoader(false);
    });
  };

  const changeFolioHandler = (e) => {
    if (e.target.value) {
      refreshForFolio();
      setSchemeLoader(true);
      let booleanSchemeType = Boolean(schemeType);
      let jsonData = JSON.parse(e.target.value);
      setFolio(e.target.value);
      setTempFolio(jsonData);
      const reqBody = {
        amc_code: amc,
        myscheme: true,
        folio: jsonData?.folio,
        rta: jsonData?.rta,
        isAssetOptCodeAvailable:false
      };
      schemeViaFolio(reqBody, headerState).then((res) => {
        setSchemeDataArr(res.data?.data);
        setSchemeLoader(false);
      });
    }
  };

  const changeSchemeHandler = (e) => {
    let jsonData;
    refreshForScheme();
    if (e.target?.value) {
      jsonData = JSON.parse(e.target?.value);
    } else {
      return;
    }

    let reqBody = {
      schemecode: Number(jsonData?.schemeCode),
    };
    setScheme(e.target?.value);
    setTempScheme(jsonData);
    getNSEProductCode(reqBody, headerState).then((res) => {
      setNseProductCode(res.data?.data?.productcode);
      setSwpAllDetails(res.data?.data);
      let data = { folio: tempFolio?.folio, productcode: jsonData?.productCode };
      getTransactionByFolioProductcode(data, headerState).then((res) => {
        if (!res.data) {
          setScheme("");
          setTempScheme({});
          notify("warn", "Please select another Scheme")
        } else {
          setAllUnitsData(res.data?.data);
          if(res.data?.data?.unit===0){
             notify("warn","You don't have sufficient units in this folio")
          }
      }
      });
    });
  };

  const checkDate = (selectedDate) => {
    const dataArr = selectedDate.split("-");
    let selectedYear = Number(dataArr[0]);
    let selectedMonth = Number(dataArr[1]);
    let selectedDay = Number(dataArr[2]);
    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth() + 1;
    let currentDay = date.getDate();
    let obj = {
      selectedYear,
      selectedMonth,
      currentYear,
      currentMonth,
      selectedDay,
      currentDay,
    };
    return obj;
  };
  const changeSwpFromHandler = (e) => {
    const obj = checkDate(e.target?.value);
    if (obj?.selectedMonth !== obj?.currentMonth &&  obj?.selectedYear !==obj?.currentYear) {
      notify(
        "error",
        "Selected date should greater than 1 month from current date. "
      );
      return;
    }
    if (swpAllDetails?.swp_dates?.includes(obj?.selectedDay) === false) {
      notify("error", "Selected day is not available");
      setSwpFrom("");
      return;
    }
    setSwpFrom(e.target?.value);
  };

  const changeSwpToHandler = (e) => {
    if (swpFrom=== e.target?.value) {
      notify(
        "error",
        "From date and to date should not be same. "
      );
      setSwpTo("");
      return;
    }
    setSwpTo(e.target.value);
  };
  const amountHandler = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue?.replace(/^0+/, ""); // Remove non-numeric characters
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    }
  };


  function formatDate(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "JAN";
      month[1] = "FEB";
      month[2] = "MAR";
      month[3] = "APR";
      month[4] = "MAY";
      month[5] = "JUN";
      month[6] = "JUL";
      month[7] = "AUG";
      month[8] = "SEP";
      month[9] = "OCT";
      month[10] = "NOV";
      month[11] = "DEC";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + "-" + month[date.getMonth()] + "-" + date.getFullYear();
    }
  }

 
  const OrderSwp = () => {
    if (
      !amount ||
      !folio ||
      !amc ||
      !profileName ||
      !scheme ||
      !swpFrom ||
      !swpTo
    ) {
      notify("error", "All Fields Required.");
      return;
    }
    // if (amount < 1000) {
    //   notify("error", "Amount Should not be less than 1000.");
    //   return
    // }
    if (allUnitsData?.unit===0) {
      notify("error", "You don't have sufficient units in this folio");
      return
    }
    setLoader("block")
    const fromDateParts = swpFrom?.split("-");
    const toDateParts = swpTo?.split("-");
    const fromDate = new Date(
      parseInt(fromDateParts[0]),
      parseInt(fromDateParts[1]) - 1,
      parseInt(fromDateParts[2])
    );
    const toDate = new Date(
      parseInt(toDateParts[0]),
      parseInt(toDateParts[1]) - 1,
      parseInt(toDateParts[2])
    );
    let reqBody = {
      email: tempProfileName?.email,
      holder_name: tempProfileName?.name,
      iin: tempProfileName?.IIN,
      tax_status: tempProfileName?.taxStatus,
      sip_paymech: "DC",
      acc_no: tempProfileName?.accountNo,
      bank: tempProfileName?.bankName,
      branch: tempProfileName?.branchName,
      acc_type: tempProfileName?.accountType,
      ifsc_code: tempProfileName?.ifscCode,
      ach_exist: "N",
      ach_fromdate: "",
      ach_enddate: "",
      schemes: [
        {
          profileName: tempProfileName?.name,
          amt_unit: amount,
          schemeName: tempScheme?.schemeName,
          product_code: swpAllDetails?.productcode,
          reinvest: swpAllDetails?.REINVEST_TAG,
          amc: amc,
          perpetual_flag: "N",
          folio: tempFolio?.folio,
          input_ref_no: "",
          from_date: formatDate(fromDate),
          to_date: formatDate(toDate),
          period_day: fromDateParts[2],
          periodicity: frequency,
          input_ref_no: "",
          insurance_enabled: "",
        },
      ],
    };
    swpTransact(reqBody, headerState).then((res) => {
      if(res.data?.status ===200){
        let nseData = {
          Unique_No: res.data?.data?.Unique_No,
          Trxn_No: res?.data?.data?.Trxn_No,
          Application_No: res?.data?.data?.Application_No,
          Fund: res?.data?.data?.Fund,
          Scheme: res?.data?.data?.Scheme,
          Scheme_Name: res?.data?.data?.Scheme_Name,
          Amt: res?.data?.data?.Amt,
          Status_Desc: res?.data?.data?.Status_Desc,
          Status_code: res?.data?.data?.Status_code,
          Folio: res?.data?.data?.Folio,
          Platform: "Web",
          transaction_type: "SWP",
          iin: tempProfileName?.IIN,
          Pan: tempProfileName?.pan,
          Gpan: tempProfileName?.gpan,
          Joint1pan: tempProfileName?.JOINT1_PAN,
          Joint2pan: tempProfileName?.JOINT2_PAN,
          name: tempProfileName?.name,
        };
        setLoader("none")
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        setConfirmationDataArr([]);
        refreshForProfile()
        setProfileName("");
        setTempProfileName({});
        saveNseTransaction(nseData, headerState).then((res) => {
         
        });
      }else{
        setLoader("none")
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
        
      }
     
    });
  };

  const dateAny = (id)=>{
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }

  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          className="invest-gray"
          scrollable={true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">SWP</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={profileChangeHandler}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                            <option
                              value={JSON.stringify({
                                email: el.EMAIL,
                                taxStatus: el.TAX_STATUS,
                                IIN: el.IIN,
                                name: el.NAME,
                                pan: el.PAN,
                                gpan: el?.GUARD_PAN,
                                jtname1: el.JOINT_NAME1,
                                jtname2: el.JOINT_NAME2,
                                nominee: el.NOM_NAME,
                                JOINT1_PAN: el.JOINT1_PAN,
                                JOINT2_PAN: el.JOINT2_PAN,
                                JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                                JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                                bankName: el.BANK_NAME,
                                bankCode: el.BANK_CODE,
                                accountNo: el.AC_NO,
                                accountType: el.AC_TYPE,
                                ifscCode: el.IFSC_CODE,
                                branchName: el.BRANCH_NAME,
                                branchAddress: el.BRANCH_ADDRESS1,
                              })}
                              key={el.PAN}
                            >
                              {`${el.NAME} ${el.JOINT_NAME1 && '|'} ${el.JOINT_NAME1}`}
                            </option>
                          ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      placeholder="Select AMC"
                      onChange={amcChangeHandler}
                      value={amc}
                      disabled={amcLoader}
                    >
                      <option value="">Select AMC</option>
                      {amcListData &&
                        amcListData.map((el) => (
                          <option value={el.amccode} key={el.amccode}>
                            {el.amc}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Folio <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={folio}
                      onChange={changeFolioHandler}
                      disabled={folioLoading}
                    >
                      <option value="">Select folio</option>
                      {folioList &&
                        folioList.map((el) => (
                          <option
                            value={JSON.stringify({
                              folio: el.folio,
                              accord_schemecode: el.accord_schemecode,
                              rta: el.rta,
                            })}
                            key={el.PAN}
                          >
                            {el.folio}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Scheme <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={scheme}
                      onChange={changeSchemeHandler}
                      disabled={schemeLoader}
                    >
                      <option value={""}>Select Scheme</option>
                      {schemeDataArr &&
                        schemeDataArr.map((el) => (
                          <option
                            value={JSON.stringify({
                              schemeCode: el.schemecode,
                              schemeName: el.shortName,
                              productCode: el.productCode
                            })}
                            key={el.schemecode}
                          >
                            {el.shortName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  {allUnitsData?.unit >= 0 ? (
                    <small className="text-red pull-left">
                      <i>
                        {" "}
                        {`Balance Units: ${allUnitsData?.unit},     Mkt. Value:  Rs ${allUnitsData?.currentvalue} `}
                      </i>
                    </small>
                  ) : null}
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      SWP From<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      id="anyWhere"
                      min={todayDate}
                      type="date"
                      onClick = {()=>dateAny("anyWhere")} 
                      value={swpFrom}
                      onChange={changeSwpFromHandler}
                    />
                    <small className="text-danger">
                      {" "}
                      {swpAllDetails?.swp_dates ? swpAllDetails?.swp_dates : ""}
                    </small>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      SWP To<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      min={todayDate}
                      type="date"
                      id="anywhere2"
                      onClick = {()=>dateAny("anywhere2")} 
                      value={swpTo}
                      onChange={changeSwpToHandler}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Frequency <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                    >
                      <option>Select Frequency</option>
                      <option value={"WD"}>Weekly </option>
                      <option value={"OM"}>Monthly </option>
                      <option value={"Q"}>Quarterly </option>
                      <option value={"BZ"}>Business Day </option>
                      <option value={"Y"}>Yearly </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Enter Amount <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder={"Enter Amount"}
                      value={amount}
                      onChange={amountHandler}
                    />
                    {/* <small className="text-label float-right">
                      Min Amount : 1000
                    </small> */}
                  </Form.Group>
                </div>
              </div>
              {/* <div className="col-md-12 " onClick={addSwp}>
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger float-right my-4"
                >
                  + Add
                </a>
              </div>
              <div className="col-md-12 px-0" id="tbt">
                <div className="table-responsive w-100">
                  <table className="table custom bg-white mt-5 mb-3">
                    <tr>
                      <th>Profile</th>
                      <th className="wd-7rem">Scheme Name</th>
                      <th className="wd-7rem">Folio Number</th>
                      <th className="wd-7rem">SWP From</th>
                      <th className="wd-7rem">SWP To</th>
                      <th className="wd-7rem">Frequency</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                    {addSwpDeatlais.length > 0 && addSwpDeatlais.map((data, i) => (
                      <tr>
                        <td>{data.profileName}</td>
                        <td>{data.schemeName}</td>
                        <td>{data.folio}</td>
                        <td>{data.from_date}</td>
                        <td>{data.to_date}</td>
                        <td>{data.periodicity}</td>
                        <td>{data.amt_unit}</td>
                        <td>
                          <Cart_Delete id={i} deleteData={deleteData} />
                        </td>
                      </tr>
                    ))}

                  </table>
                </div>
              </div> */}
            </Form>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button className="btn-custom" type="button" onClick={OrderSwp}>
              Order Now
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      {/* <TransactFailed show={confirmShow} setShow={setConfirmShow} /> */}
      <SWW  show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg ={errorMsg}/>
    </>
  );
};
export default SWPTransact;
