import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import SWW from "./something-went-wrong";
import TransactSuccess from "./transaction-successforRedumption";
import Form from "react-bootstrap/Form";
import {
  amcByProfile,
  folioViaAmc,
  getNSEProductCode,
  getTransactionByFolioProductcode,
  redeem,
  saveNseTransaction,
  schemeViaFolio,
} from "../apisMethods";
import { notify } from "../pages/toastCreater";
import { globalInfo } from "../App";

const RedemptionTransact = ({ show, setShow, amcList, userProfileData }) => {
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [amc, setAmc] = useState("");
  const [folio, setFolio] = useState("");
  const [folioList, setFolioList] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [tempProfileName, setTempProfileName] = useState({});
  const [scheme, setScheme] = useState("");
  const [schemeType, setSchemeType] = useState("true");
  const [switchType, setSwitchType] = useState("Amount");
  const [amount, setAmount] = useState();
  const [amcListData, setAmcListData] = useState([]);
  const [headerState, setHeaderState] = useState({});
  const [tempFolio, setTempFolio] = useState({});
  const [nseProductCode, setNseProductCode] = useState("");
  const [schemeDataArr, setSchemeDataArr] = useState([]);
  const [allUnitsData, setAllUnitsData] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [amcLoader, setAmcLoader] = useState(false);
  const [folioLoading, setFolioLoading] = useState(false);
  const [schemeLoader, setSchemeLoader] = useState(false);

  // const token = localStorage.getItem("token");
  // const headers = { headers: { Authorization: `Bearer ${token}` } };

  const refreshForProfile = () => {
    setAmcListData([]);
    setFolioList([]);
    setSchemeDataArr([]);
    setAllUnitsData({})
    setAmount("");
    setAmc("")
    setTempFolio({})
    setFolio("")
    setScheme("")

  };

  const refreshForAmc = () => {
    setFolioList([]);
    setSchemeDataArr([]);
    setAllUnitsData({})
    setTempFolio({})
    setAmount("");
    setFolio("")
    setTempFolio({})
    setScheme("")
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
  }, []);

  const amcChangeHandler = (e) => {
    const value = e.target.value;
    setAmc(value);

    if (value) {
      refreshForAmc();
      setFolioLoading(true);
      let joint1stPan = "";
      let joint2ndPan = "";

      if (tempProfileName?.JOINT1_PAN) {
        joint1stPan = tempProfileName?.JOINT1_PAN;
      } else if (tempProfileName?.JOINT1_VALIDPAN?.length > 1) {
        joint1stPan = tempProfileName?.JOINT1_VALIDPAN;
      } else {
        joint1stPan = "";
      }
      if (tempProfileName?.JOINT2_PAN) {
        joint2ndPan = tempProfileName?.JOINT2_PAN;
      } else if (tempProfileName?.JOINT2_VALIDPAN?.length == 10) {
        joint2ndPan = tempProfileName?.JOINT2_VALIDPAN;
      } else {
        joint2ndPan = "";
      }
      const reqBody = {
        investor_pan: tempProfileName?.pan,
        jh1_pan: joint1stPan,
        jh2_pan: joint2ndPan,
        amccode: value,
        gpan: tempProfileName?.gpan ? tempProfileName?.gpan:"",
      };
      folioViaAmc(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("error", res.error?.response?.data?.msg);
          setFolioLoading(false);
        } else {
          setFolioList(res.data?.data);
          setFolioLoading(false);
        }
      });
    } else {
      setFolioList([]);
    }
  };

  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      refreshForProfile();
      setAmcLoader(true);
      let reqData = JSON.parse(e.target?.value);
      refreshState();
      setProfileName(e.target?.value);
      setTempProfileName(reqData);
      amcByProfileCall(reqData);
    }
  };
  const refreshState = () => {
    setAmc("");
    setFolio("");
    setScheme("");
    setAmount("");
    setFolioList([]);
    setSchemeDataArr([]);
    setAllUnitsData({})
  };

  const amcByProfileCall = (reqData) => {
    let joint1stPan = "";
    let joint2ndPan = "";

    if (reqData?.JOINT1_PAN) {
      joint1stPan = reqData?.JOINT1_PAN;
    } else if (reqData?.JOINT1_VALIDPAN?.length === 10) {
      joint1stPan = reqData?.JOINT1_VALIDPAN;
    } else {
      joint1stPan = "";
    }
    if (reqData.JOINT2_PAN) {
      joint2ndPan = reqData?.JOINT2_PAN;
    } else if (reqData?.JOINT2_VALIDPAN?.length === 10) {
      joint2ndPan = reqData?.JOINT2_VALIDPAN;
    } else {
      joint2ndPan = "";
    }
    const reqBody = {
      investor_pan: reqData?.pan,
      jh1_pan: joint1stPan,
      jh2_pan: joint2ndPan,
      gpan: reqData?.gpan ? reqData?.gpan :"",
    };
    amcByProfile(reqBody, headerState).then((res) => {
      setAmcListData(res.data?.data);
      setAmcLoader(false);
    });
  };

  const changeFolioHandler = (e) => {
    if (e.target.value) {
      setSchemeLoader(true);
      setScheme("");
      setAllUnitsData({})
      let booleanSchemeType = Boolean(schemeType);
      let jsonData = JSON.parse(e.target?.value);
      setFolio(e.target?.value);
      setTempFolio(jsonData);
      const reqBody = {
        amc_code: amc,
        myscheme: true,
        folio: jsonData?.folio,
        rta: jsonData?.rta,
        isAssetOptCodeAvailable: false
      };
      schemeViaFolio(reqBody, headerState).then((res) => {
        setSchemeDataArr(res.data?.data);
        setSchemeLoader(false);
      });
    }
  };

  const changeSchemeHandler = (e) => {
    if (e.target.value) {
      if (!tempFolio?.folio || !amc) {
        return
      }
      let schemeData = JSON.parse(e.target?.value);
      let reqBody = {
        schemecode: Number(schemeData?.schemecode),
      };


      setScheme(e.target?.value);
      getNSEProductCode(reqBody, headerState).then((res) => {
        setNseProductCode(res.data?.data?.productcode);
        let data = { folio: tempFolio?.folio, productcode: schemeData?.productCode };
        getTransactionByFolioProductcode(data, headerState).then((res) => {
          if (!res.data) {
            notify("warn", "Please select another Scheme")
          } else {
            setAllUnitsData(res.data?.data);
            if (res.data?.data?.unit === 0) {
              notify("warn", "You don't have sufficient units in this folio")
            } else {
              if (switchType === "Y") {
                setAmount(res.data?.data?.unit);
              }
            }
          }
        });
      });
    }

  };
  const amountHandler = (e) => {
    const inputValue = e.target.value;

    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    }
  };

  const orderRedumption = () => {
    let checkAmount = Number(amount);
    if (!checkAmount || !folio || !amc || !profileName || !scheme) {
      notify("error", "All Fields Required.");
      return;
    }
    if (checkAmount > allUnitsData?.unit && switchType === "Unit") {
      notify("warn", "Entered units should not be greater than the available units");
      return;
    }
    if (
      checkAmount > Number(allUnitsData?.currentvalue) &&
      switchType === "Amount"
    ) {
      notify("warn", "Entered amount should not be greater than the available amount.");
      return;
    }
    // if (amount < 1000) {
    //   notify("error", "Amount Should not be less than 1000.");
    //   return
    // }
    if (allUnitsData?.currentvalue === 0) {
      notify("error", "You don't have sufficient units in this folio");
      return
    }
    setLoader("block")
    const reqBody = {
      email: tempProfileName?.email,
      tax_status: tempProfileName?.taxStatus,
      trxn_acceptance: "ALL", // Static
      amc: amc,
      product_code: nseProductCode,
      amt_unit: amount,
      remark: "",
      folio: tempFolio?.folio,
      amt_unit_type: switchType === "Y" || switchType === "Unit" ? "Unit" : "Amount",
      all_units: switchType !== "Y" ? "N" : "Y",
      input_ref_no: "",
      iin: tempProfileName?.IIN,
    };

    redeem(reqBody, headerState).then((res) => {

      if (res.data?.status === 200) {
        setLoader("none")
        let nseData = {
          Unique_No: res?.data?.data?.Unique_No,
          Trxn_No: res?.data?.data?.Trxn_No,
          Application_No: res?.data?.data?.Application_No,
          Fund: res?.data?.data?.Fund,
          Scheme: res?.data?.data?.Scheme,
          Scheme_Name: res?.data?.data?.Scheme_Name,
          Amt: res?.data?.data?.Amt,
          Status_Desc: res?.data?.data?.Status_Desc,
          Status_code: res?.data?.data?.Status_code,
          Folio: res?.data?.data?.Folio,
          Platform: "Web",
          transaction_type: "Redemption",
          iin: tempProfileName?.IIN,
          Pan: tempProfileName?.pan,
          Gpan: tempProfileName?.gpan ? tempProfileName?.gpan :"",
          Joint1pan: tempProfileName?.JOINT1_PAN,
          Joint2pan: tempProfileName?.JOINT1_PAN,
          name: tempProfileName?.name,
        };
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        setConfirmationDataArr([]);
        refreshForProfile()
        setProfileName('')
        setTempProfileName({})
        saveNseTransaction(nseData, headerState).then((res) => {

        });
      } else {
        setLoader("none")
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }


    });
  };
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          className="invest-gray "
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={profileChangeHandler}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                          <option
                            value={JSON.stringify({
                              email: el.EMAIL,
                              taxStatus: el.TAX_STATUS,
                              IIN: el.IIN,
                              name: el.NAME,
                              pan: el.PAN,
                              gpan: el?.GUARD_PAN,
                              jtname1: el.JOINT_NAME1,
                              jtname2: el.JOINT_NAME2,
                              nominee: el.NOM_NAME,
                              JOINT1_PAN: el.JOINT1_PAN,
                              JOINT2_PAN: el.JOINT2_PAN,
                              JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                              JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                            })}
                            key={el.PAN}
                          >
                            {`${el.NAME} ${el.JOINT_NAME1 && '|'} ${el.JOINT_NAME1}`}
                          </option>
                        ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      placeholder="Select AMC"
                      onChange={amcChangeHandler}
                      value={amc}
                      disabled={amcLoader}
                    >
                      <option value="">Select AMC</option>
                      {amcListData &&
                        amcListData.map((el) => (
                          <option value={el.amccode} key={el.amccode}>
                            {el.amc}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Folio <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={folio}
                      onChange={changeFolioHandler}
                      disabled={folioLoading}
                    >
                      <option value="">Select folio</option>
                      {folioList &&
                        folioList.map((el) => (
                          <option
                            value={JSON.stringify({
                              folio: el.folio,
                              accord_schemecode: el.accord_schemecode,
                              rta: el.rta,
                            })}
                            key={el.PAN}
                          >
                            {el.folio}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>
                      Select Scheme <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={scheme}
                      onChange={changeSchemeHandler}
                      disabled={schemeLoader}
                    >
                      <option value={""}>Select Scheme</option>
                      {schemeDataArr &&
                        schemeDataArr.map((el) => (
                          <option value={JSON.stringify({
                            schemecode: el?.schemecode, productCode: el.productCode
                          })} key={el.schemecode}>
                            {el.shortName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  {allUnitsData?.unit >= 0 ? (
                    <small className="text-red pull-left">
                      <i>
                        {`Available  Units: ${allUnitsData?.unit},     Total Amount:  Rs ${allUnitsData?.currentvalue} `}
                      </i>
                    </small>
                  ) : null}
                </div>
                <div className="col-md-7">
                  <Form.Group className=" " controlId="formBasicRadio">
                    <Form.Label>
                      Redemption Type <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="By Amount"
                        name="formHorizontalRadios"
                        id="ByAmount"
                        value={"Amount"}
                        onChange={(e) => {
                          setAmount("");
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Amount"}
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                      <Form.Check
                        type="radio"
                        label="By Units"
                        value={"Unit"}
                        onChange={(e) => {
                          setAmount("");
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Unit"}
                        name="formHorizontalRadios"
                        id="ByUnits"
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                      <Form.Check
                        type="radio"
                        label="All Units"
                        name="formHorizontalRadios"
                        value={"Y"}
                        onChange={(e) => {
                          setAmount(allUnitsData?.unit);
                          setSwitchType(e.target.value);
                        }}
                        checked={switchType == "Y"}
                        id="AllUnits"
                        className="pe-lg-3 pe-2 fs-sm-14"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5">
                  <Form.Group>
                    <Form.Label>
                      Enter
                      {switchType == "Y" ? "All Units" : " " + switchType}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder={switchType == "Y" ? "All Units" : switchType}
                      value={amount}
                      onChange={amountHandler}
                    />
                    {/* <small className="text-label float-right">
                      Min Amount : 1000
                    </small> */}
                  </Form.Group>
                </div>
              </div>
              {/* <div className="col-md-12 "
              // onClick={addTransact}
              >
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger  my-4 float-right"
                >
                  + Add
                </a>
              </div> */}
              {/* <div className="col-md-12 px-0" id="tbt">
                <div className="table-responsive w-100">
                  <table className="table custom bg-white mt-5 mb-3">
                    <tr>
                      <th>Profile</th>
                      <th className="wd-7rem">Scheme Name</th>
                      <th className="wd-7rem">Folio Number</th>
                      <th className="wd-7rem">Redemption Type</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>

                    <tr >
                      <td>el.profile</td>
                      <td>el.scheme</td>
                      <td>el.folio</td>
                      <td>
                        el.switchType === "Y"

                      </td>
                      <td>el.amount</td>
                      <td>
                      </td>
                    </tr>
                  </table>
                </div>
              </div> */}
            </Form>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              className="btn-custom"
              type="button"
              onClick={orderRedumption}
            >
              Order Now
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
    </>
  );
};
export default RedemptionTransact;
