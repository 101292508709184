import { request, apis } from "../request";

export const registerOtp = async (body) => {
  return await request(apis.post_registerUserOTP, body);
};

export const verifyMethodOtp = async (body) => {
  return await request(apis.post_verifyOtp, body);
};

export const registrationUser = async (body) => {
  return await request(apis.post_userRegisterViaEmail, body);
};

export const sendResetOtp = async (body) => {
  return await request(apis.post_sendResetOtp, body);
};

export const verifyResetOtp = async (body) => {
  return await request(apis.post_verifyResetOtp, body);
};

export const loginViaEmailAndPass = async (body) => {
  return await request(apis.post_loginWithEmail, body);
};

export const resetPassword = async (body, header) => {
  return await request(apis.post_resetPassword, body, header);
};

export const registerUserViaSocialId = async (body) => {
  return await request(apis.post_registerUserViaSocialId, body);
};

export const loginUserViaSocialId = async (body) => {
  return await request(apis.post_loginViaGoogle, body);
};

export const sendMobileLoginOtp = async (body) => {
  return await request(apis.post_sendMobileLoginOtp, body);
};


export const verifyMobileLoginOtp = async (body) => {
  return await request(apis.post_verfiyMobileLoginOtp, body);
};

export const getUserStatus = async (header) => {
  return await request(apis.get_userStatus, null, header);
};

export const checkPanStatus = async (body, headers) => {
  return await request(apis.post_checkPanStatus, body, headers);
};

export const registerPan = async (body, headers) => {
  return await request(apis.post_registerPan, body, headers);
};
export const resetPin = async (body,header) => {
  return await request(apis.post_resetPin, body,header);
};
export const sendPanOtpAddfamily = async (body,header) => {
  return await request(apis.post_sendPanOtpAddfamily, body,header);
};
export const verifiyPanOtpAddfamily = async (body,header) => {
  return await request(apis.post_verifiyPanOtpAddfamily, body,header);
};
export const getFamilyList = async (body,header) => {
  return await request(apis.post_getFamilyList, body,header);
};
export const deleteFamilyMember = async (body,header) => {
  return await request(apis.post_deleteFamilyMember, body,header);
};
export const getAllFamily = async (body,header) => {
  return await request(apis.post_getAllFamily, body,header);
};
export const nseUserRelation = async (headers) => {
  return await request(apis.get_nseUserRelation, null, headers);
};
export const updateUserProfile = async (body,header) => {
  return await request(apis.post_updateUserProfile, body,header);
};

export const savePan = async (body,header)=> {
 return await request(apis.post_savePan,body,header);
}
export const updateUserStep = async (body,header)=> {
  return await request(apis.post_updateUserStep,body,header);
 }
export const userExist = async (body)=> {
  return await request(apis.post_userExists,body);
 }
 export const updateProfilePic = async (body,header)=> {
  return await request(apis.post_updateProfilePic,body,header);
 }
 export const kycLinkOnEmail = async (body,header)=> {
  return await request(apis.post_kycLinkOnEmail,body,header);
 }
 export const saveUpdateBasket = async (body,header)=> {
  return await request(apis.post_saveUpdateBasket,body,header);
 }
 export const getUserBasket = async (header)=> {
  return await request(apis.get_getUserBasket,null,header);
 }
 export const removeBasketData = async (body,header)=> {
  return await request(apis.post_removeBasketData,body,header);
 }
 export const connectRm = async (body,header)=> {
  return await request(apis.post_connectRm,body,header);
 }
 export const storeDeviceToken = async (body,header)=> {
  return await request(apis.post_storeDeviceToken,body,header);
 }
 export const rateRm = async (body,header)=> {
  return await request(apis.post_rateRm,body,header);
 }