export const baseUrl = "https://uat.bfccapital.com/prodigypro/api";

// export const baseUrl = "http://192.168.1.3:8080/api";

export const apis = {
  imageLink: "https://prodigyproamcs.s3.ap-southeast-1.amazonaws.com/",
  post_registerUserOTP: "/register/user-register-otpEmail",
  post_verifyOtp: "/register/verify-otp",
  post_userRegisterViaEmail: "/register/user-register-via-email-v1",
  post_resetPassword: "/user/reset-password",
  post_sendResetOtp: "/user/send-reset-otp",
  post_sendResetOtp: "/user/send-reset-otp",
  post_verifyResetOtp: "/user/verify-reset-otp",
  post_resetPin: "/user/reset-mpin",
  post_loginWithEmail: "/user/login-via-email",
  post_registerUserViaSocialId: "/register/user-register-via-socialid-v1",
  post_loginViaGoogle: "/user/login-via-socialID",
  post_sendMobileLoginOtp: "/user/send-mobile-login-otp",
  post_verfiyMobileLoginOtp: "/user/verify-login-otp",
  get_userStatus: "/user/user-status",
  post_userExists: "/user/user-exists",
  post_sendPanOtpAddfamily: "/user/send-pan-otp-addfamily",
  post_verifiyPanOtpAddfamily: "/user/verifiy-pan-otp-addfamily",
  post_getFamilyList: "/user/get-family-list",
  post_deleteFamilyMember: "/user/delete-family-member",
  post_getAllFamily: "/user/get-all-family",
  get_nseUserRelation: "/user/get-nse-user-relation",
  post_updateUserProfile: "/user/update-user-profile",
  post_saveUpdateBasket: "/user/save-update-basket",
  get_getUserBasket: "/user/get-user-basket",
  post_removeBasketData: "/user/remove-basket-data",
  post_connectRm: "/user/contact-rm",
  post_storeDeviceToken:"/user/store-device-token",
  post_rateRm:"/user/rate-rm",

  post_checkPanStatus: "/register/check-update-pan",
  post_registerPan: "/register/user-register-pan",
  post_createProfile: "/register/iin/create-profile",
  post_pincode: "/register/iin/get-details-by-PIN",
  get_incomerange: "/register/iin/get-income-range",
  get_relation: "/register/iin/get-family-relations",
  get_occupation: "/register/iin/get-occupation-nse",
  post_addNomini: "/register/iin/add-nominee-details",
  post_getIfscDetails: "/register/iin/get-bankdetails-ifsc",
  post_addBankDetails: "/register/iin/add-bank-details",
  post_createPrifileJointHolder: "/register/iin/joint-holder",
  post_getNominees: "/register/iin/get-nominee",
  post_deleteNominees: "/register/iin/delete-nominee",
  post_updateNominees: "/register/iin/update-nominee",
  post_create_iin_nse: "/register/iin/create-iin-single",


  post_reportsSipStpSwp: "/reports/sip-stp-swp-report",
  post_getReportTransaction: "/reports/get-transactions",
  post_getTaxSavingReport: "/reports/get-tax-saving-report",
  post_getDevidendReport: "/reports/get-dividend-report",
  get_amcList: "/transact/get-amc",
  post_iinCreateFatca: "/register/iin/create-fatca",
  post_getIIn: "/register/iin/get-user-iin",
  post_getScheme: "/transact/get-scheme",
  post_getFolioByAmc: "/transact/get-folio-byAMC",
  get_bankList: "/register/iin/get-banklist",
  post_checkExistingIin: "/register/iin/check-existing-iin",
  post_varifyFatca: "/register/iin/verify-fatca",
  post_getBankDetailsUser: "/register/iin/get-bank-details-user",
  post_bankProofUploadTemp: "/register/iin/bank-birth-proof-upload-temp",
  post_updateDetailsTempIin: "/register/update-details-tempiin",
  post_getTempIinsData: "/register/get-tempiins-data",
  post_getJointHolder: "/register/iin/get-joint-holder",
 post_createIinAos: "/register/iin/create-iin-aos",
 post_createFatcaOne: "/register/iin/create-fatca-one",

  post_uploadBankBirthProof: "/register/iin/upload-bank-birth-proof",
  post_familySnapshot: "/portfolio/get-family-snapshot",
  post_familyWisePortfolio: "/portfolio/get-familywise-portfolio",
  post_detailedPortfolio: "/portfolio/get-detailed-portfolio",
  post_transactionDetail: "/portfolio/get-portfolio-transaction-detail",
  post_personalDetail: "/portfolio/get-portfolio-personal-detail",
  post_personalDetailByFolio: "/portfolio/get-Personaldetail-by-folio",

  // Portfolio Review

  post_portfolioFamilyData: "/portfolio-review-prodigy/get-family-data",
  post_setTime: "/portfolio-review-prodigy/set-time",
  get_getTime: "/portfolio-review-prodigy/get-time",
  post_recommendationRedemptionTransact: "/portfolio-review-prodigy/recommendation-redemption",
  post_recommendationSatisfactory: "/portfolio-review-prodigy/recommendation-satisfactory",
  post_recommendationUnderwatch: "/portfolio-review-prodigy/recommendation-underwatch",
  post_recommendationSwitch: "/portfolio-review-prodigy/recommendation-switch",
  post_redeemMulti: "/transact/redeem-multi",
  post_switchMulti: "/transact/switch-multi",
  get_getNfos: "/transact/get-nfos",
  // transact Api

  post_userProfile: "/user/get-user-profile",
  post_userProfileIIN: "/user/get-user-profile-iins",
  post_getNSEProductCode: "/transact/get-nse-products",
  post_newPurchase: "/transact/single-purchase",
  post_amcByProfile: "/transact/get-amc-via-profile",
  post_folioViaAmc: "/transact/get-folio-via-amc",
  post_schemeViaFolio: "/transact/get-scheme-via-folio-amc",
  post_usersBank: "/transact/get-users-bank",
  post_saveNseTransaction: "/transact/save-nse-transaction",
  post_multiplePurchase: "/transact/multiple-purchase",
  post_redeem: "/transact/redeem",
  post_getTransactionByFolioProductcode:
    "/transact/get-transaction-by-folio-productcode",
  post_switch: "/transact/switch",
  post_swp: "/transact/swp",
  post_stp: "/transact/stp",
  post_sip: "/transact/sip",
  post_nseTransactionReport: "/transact/nse-transaction-report",
  post_getMandateList: "/transact/get-mandate-list",
  post_getUserProfileViaPortfolio: "/transact/get-user-profile-via-portfolio",
  post_getElssScheme: "/transact/get-elss-schemes",

  post_getGoalPlanning: "/calculators/goal-planning",
  post_saveGoalPlanning: "/calculators/save-goal-plan",
  get_getAllGoalPlans: "/calculators/get-plans",
  post_deleteGoalPlan: "/calculators/delete-goals",
  post_updateGoalPlan: "/calculators/update-goal-plan",
  post_swpCalculator: "/calculators/swp",

  post_getUserProfileViaPortfolio: "/transact/get-user-profile-via-portfolio",

  // miscellaneaus
  post_portfolioCapitalReview: "/emails/portfolio-capital-review",
  post_contactUs: "/emails/contact-us",
  post_kycLinkOnEmail: "/emails/kyc-link-email",

  // basket

  get_RightSchemeDurationRisk: "/product-basket/get-right-scheme-duration-risk",
  post_getRightSchemesNse: "/product-basket/get-right-schemes-nse",
  get_simplySipDuration: "/product-basket/get-simply-sip-duration",
  post_simplySipSchemes: "/product-basket/get-simply-sip-schemes",
  get_taxPlanningNseSchemes: "/product-basket/get-tax-planning-nse-schemes",
  get_simplySaveNseSchemes: "/product-basket/get-simply-save-nse-schemes",
  post_GoalPlanningSchemes: "/product-basket/get-goal-planning-schemes",

  //schemePerformance

  get_productBasketAmcList: "/product-basket/get-amc-list",
  get_productBasketCategories: "/product-basket/get-categoryTypes-list",
  get_productBasketAsset: "/product-basket/get-assetTypes-list",
  post_productBasketFilteredScheme: "/product-basket/get-filtered-scheme",
  post_getHistoricNav: "/product-basket/get-historic-nav",
  post_schemeFromNse: "/product-basket/scheme-from-nse",

  //user
  post_savePan: "/user/save-pan",
  post_updateUserStep: "/user/update-user-step",
  post_updateProfilePic: "/user/update-profile-pic",
  get_getUserIinsPending: "/user/get-useriins-pending",

  // mandate
  post_createAchMandate: "/mandate/create-ach-mandate",
  post_addBank: "/mandate/create-bank-mandate",
  post_uploadBankProof: "/mandate/upload-bank-mandate-doc",

  post_pendingMandateList: "/mandate/get-mandate-list",
  post_retriggerMandate: "/mandate/retrigger-mandate",
  post_updateNseMultiUserBankdetails: "/mandate/update-nse-multi-user-bankdetails",
  // post_getJointHolder:"/register/iin/get-joint-holder"

  /// notification 

  post_markNotifications:"/notifications/mark-notifications",
  post_deleteNotifications:"/notifications/delete-notifications",
  get_getNotifications:"/notifications/get-notifications"

};
