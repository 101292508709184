import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Cart_Delete from "./delete-confirmation";
import Form from "react-bootstrap/Form";
import { notify } from "../pages/toastCreater";
import TransactSuccess from "./transact-success";
import SWW from "./something-went-wrong";
import { globalInfo } from "../App";
import {
  amcByProfile,
  folioViaAmc,
  schemeViaFolio,
  usersBank,
  getNSEProductCode,
  newPurchase,
  multiplePurchase,
  saveNseTransaction,
  getMandateList,
  updateNseMultiUserBankdetails,
} from "../apisMethods";
import { disableFutureDate } from "../currentMonth/FinancialYear";
import ConfirmationProfileChangePopup from "./confirmationProfileChangePopup";
import ConfirmationMandatePopup from "./mandateConfirmationPopup";

const AddPurchaseTransact = ({ show, setShow, amcList, userProfileData }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [amc, setAmc] = useState("");
  const [tempamc, setTempAmc] = useState({});
  const [folio, setFolio] = useState("");
  const [folioList, setFolioList] = useState([]);
  const [folioLoading, setFolioLoading] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [tempProfileName, setTempProfileName] = useState({});
  const [scheme, setScheme] = useState("");
  const [tempScheme, setTempScheme] = useState({});
  const [nseSchemeData, setNseSchemeData] = useState({});
  const [schemeType, setSchemeType] = useState("true");
  const [assetClass, setAssetClass] = useState("1");
  const [switchType, setSwitchType] = useState("Amount");
  const [option, setOption] = useState("1");
  const [targetScheme, setTargetScheme] = useState("");
  const [amount, setAmount] = useState();
  const [switchList, setSwitchList] = useState([]);
  const [amcListData, setAmcListData] = useState([]);
  const [headerState, setHeaderState] = useState({});
  const [tempFolio, setTempFolio] = useState({});
  const [paymentMode, setPaymentMode] = useState("");
  const [hideRTGS, setHideRTGS] = useState("d-none");
  const [hideMandate, setHideMandate] = useState("d-none");
  const [hideImidiatePayment, setHideImidiatePayment] = useState("d-none");
  const [imediateOrEmail, setImediateOrEmail] = useState("Y");
  const [usersBankDetail, setUsersBankDetail] = useState([]);
  const [bankName, setBankName] = useState("");
  const [tempBankName, setTempBankName] = useState({});
  const [mandateListData, setMandateListData] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [additionalPurchaseDetails, setAdditionalPurchaseDetails] = useState(
    []
  );
  const [mandateUrn, setMandateUrn] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [amcLoader, setAmcLoader] = useState(false);
  const [folioLoader, setFolioLoader] = useState(false);
  const [schemeLoader, setSchemeLoader] = useState(false);
  const [tempMandateUrn, setTempMandateUrn] = useState({});
  const [hideBank, setHideBank] = useState("d-none");
  const [tempOrderData, setTempOrderData] = useState({});
  const [removeFutureDAte, setRemoveFutureDAte] = useState("");
  const [isProfileChange, setIsProfileChange] = useState(false);
  const [massageContent, setMassageContent] = useState("");
  const [profileTargetValue, setProfileTargetValue] = useState("")
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    let d = disableFutureDate();
    setRemoveFutureDAte(d);
  }, []);

  const amcChangeHandler = (e) => {
    setFolioLoader(true);
    setFolio("");
    setScheme("");
    setSchemeList([]);
    let value = e.target.value;
    if (e.target.value) {
      setAmc(e.target.value);
      if (value) {
        // setFolioLoading(true);
        let joint1stPan = "";
        let joint2ndPan = "";

        if (tempProfileName?.JOINT1_PAN) {
          joint1stPan = tempProfileName?.JOINT1_PAN;
        } else if (tempProfileName?.JOINT1_VALIDPAN?.length > 1) {
          joint1stPan = tempProfileName?.JOINT1_VALIDPAN;
        } else {
          joint1stPan = "";
        }
        if (tempProfileName?.JOINT2_PAN) {
          joint2ndPan = tempProfileName?.JOINT2_PAN;
        } else if (tempProfileName?.JOINT2_VALIDPAN?.length == 10) {
          joint2ndPan = tempProfileName?.JOINT2_VALIDPAN;
        } else {
          joint2ndPan = "";
        }

        const reqBody = {
          investor_pan: tempProfileName?.pan,
          jh1_pan: joint1stPan,
          jh2_pan: joint2ndPan,
          amccode: value,
          gpan: tempProfileName?.gpan ? tempProfileName?.gpan:"",
        };
        folioViaAmc(reqBody, headerState).then((res) => {
          if (!res.data) {
            notify("error", res.error?.response?.data?.msg);
            // setFolioLoading(false);
            setFolioLoader(false);
          } else {
            setFolioList(res.data?.data);
            setFolioLoader(false);
          }
        });
      } else {
        setFolioList([]);
      }
    }
  };

  const deleteData = (i) => {
    additionalPurchaseDetails.splice(i, 1);
    setAdditionalPurchaseDetails([...additionalPurchaseDetails]);
  };

  const isWantProfileChange = (e) => {
    console.log(e.target.value);
    
    if (additionalPurchaseDetails?.length > 0) {
      setMassageContent("Change of profile will lead to deletion of added schemes. Are you sure to proceed?")
      setProfileTargetValue(e.target.value)
      setIsProfileChange(true)
    } else {
      profileChangeHandler(e.target.value)
    }

  }
  const profileChangeHandler = (e) => {
    if (e !== "") {
      let reqData = JSON.parse(e);
      setProfileName(e);
      setTempProfileName(reqData);
      refreshState();
      amcByProfileCall(reqData);
      setLoader("block")
      updateNseMultiUserBankdetails({iin:reqData?.IIN},headerState).then(res=>{
        setLoader("none")
        usersBankCall(reqData.IIN);
      })
     
    }
  };
  const refreshState = () => {
    setAmc("");
    setFolio("");
    setScheme("");
    setAmount("");
    setSchemeList([]);
    setFolioList([]);
    setPaymentMode("");
    setBankName("");
    setTempBankName({});
    setMandateUrn("");
    setTempMandateUrn({});
    setAdditionalPurchaseDetails([]);
    setHideRTGS("d-none")
    setHideMandate("d-none")
    setHideBank("d-none")
    setHideImidiatePayment("d-none")
  };

  const amcByProfileCall = (reqData) => {
    let joint1stPan = "";
    let joint2ndPan = "";
    setAmcLoader(true);
    if (reqData?.JOINT1_PAN) {
      joint1stPan = reqData?.JOINT1_PAN;
    } else if (reqData?.JOINT1_VALIDPAN?.length === 10) {
      joint1stPan = reqData?.JOINT1_VALIDPAN;
    } else {
      joint1stPan = "";
    }
    if (reqData?.JOINT2_PAN) {
      joint2ndPan = reqData?.JOINT2_PAN;
    } else if (reqData?.JOINT2_VALIDPAN?.length === 10) {
      joint2ndPan = reqData?.JOINT2_VALIDPAN;
    } else {
      joint2ndPan = "";
    }
    const reqBody = {
      investor_pan: reqData?.pan,
      jh1_pan: joint1stPan,
      jh2_pan: joint2ndPan,
      gpan: reqData?.gpan ? reqData?.gpan:"",
    };
    amcByProfile(reqBody, headerState).then((res) => {
      setAmcListData(res.data?.data);
      setAmcLoader(false);
    });
  };

  const changeFolioHandler = (e) => {
    if (e.target.value) {
      setScheme("");
      // setSchemeLoader(true);
      let jsonData = JSON.parse(e.target.value);
      setFolio(e.target.value);
      setTempFolio(jsonData);
      let reqBody = {};
      if (schemeType === "true") {
        reqBody = {
          amc_code: amc,
          myscheme: true,
          folio: jsonData?.folio,
          rta: jsonData?.rta,
          isAssetOptCodeAvailable: false,
        };
      } else {
        reqBody = {
          amc_code: amc,
          myscheme: false,
          folio: jsonData?.folio,
          rta: jsonData?.rta,
          opt_code: Number(option),
          asset_code: Number(assetClass),
          isAssetOptCodeAvailable: true,
        };
      }

      schemeDataCall("callFromFolio", reqBody);
    }
  };
  const schemeDataCall = (e, reqBody) => {
    setSchemeLoader(true);
    setScheme("");
    if (e === "callFromFolio") {
      schemeViaFolio(reqBody, headerState).then((res) => {
        if (!res.data) {
          setSchemeList([]);
          // notify("warn", "something went wrong...!");
        } else {
          setSchemeList(res.data?.data);
        }
        setSchemeLoader(false);
      });
    } else if (e !== "callFromFolio") {
      setSchemeType(e.target.value);
      if (!amc || !tempFolio?.folio) {
        setSchemeLoader(false);
        return;
      }

      let reqBody = {};
      if (e.target.value === "true") {
        reqBody = {
          amc_code: amc,
          myscheme: true,
          folio: tempFolio?.folio,
          rta: tempFolio?.rta,
          // opt_code: Number(option),
          // asset_code: Number(assetClass),
          isAssetOptCodeAvailable: false,
        };
      } else {
        reqBody = {
          amc_code: amc,
          myscheme: false,
          folio: tempFolio?.folio,
          rta: tempFolio?.rta,
          opt_code: Number(option),
          asset_code: Number(assetClass),
          isAssetOptCodeAvailable: true,
        };
      }

      schemeViaFolio(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("warn", "something went wrong...!");
          setSchemeList([]);
        } else {
          setSchemeList(res.data?.data);
        }
        setSchemeLoader(false);
      });
    }
  };

  const assetClassHandler = (e) => {
    setAssetClass(e.target.value);

    if (!amc || !tempFolio?.folio) {
      setSchemeList([]);
      return;
    }
    setSchemeLoader(true);
    let reqBody = {
      amc_code: amc,
      myscheme: schemeType === "true" ? true : false,
      folio: tempFolio?.folio,
      rta: tempFolio?.rta,
      opt_code: Number(option),
      asset_code: Number(e.target.value),
      isAssetOptCodeAvailable: true,
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        notify("warn", "something went wrong...!");
        setSchemeList([]);
      } else {
        setSchemeList(res.data?.data);
      }
      setSchemeLoader(false);
    });
  };

  const optionHandler = (e) => {
    setOption(e.target.value);

    if (!amc || !tempFolio?.folio) {
      setSchemeList([]);
      return;
    }
    setSchemeLoader(true);
    let reqBody = {
      amc_code: amc,
      myscheme: schemeType === "true" ? true : false,
      folio: tempFolio?.folio,
      rta: tempFolio?.rta,
      opt_code: Number(e.target.value),
      asset_code: Number(assetClass),
      isAssetOptCodeAvailable: true,
    };
    schemeViaFolio(reqBody, headerState).then((res) => {
      if (!res.data) {
        notify("warn", "something went wrong...!");
        setSchemeList([]);
      } else {
        setSchemeList(res.data?.data);
      }
      setSchemeLoader(false);
    });
  };
  const changePaymentModeHandler = (e) => {
    let value = e.target.value;
    setPaymentMode(e.target.value);
    if (value === "OL" || value === "UPI") {
      setHideRTGS("d-none");
      setHideBank("");
      setHideMandate("d-none");
      setHideImidiatePayment("");
      setMandateUrn("");
      setMandateUrn("");
      setBankName("");
      setTempBankName({});
      setTempMandateUrn({});
    } else if (value === "TR") {
      setHideRTGS("");
      setHideBank("");
      setHideMandate("d-none");
      setHideImidiatePayment("d-none");
      setMandateUrn("");
      setMandateUrn("");
      setTempMandateUrn({});
      setBankName("");
      setTempBankName({});
    } else if (value === "M") {
      setHideBank("d-none");
      setMandateUrn("");
      setTempMandateUrn({});
      setBankName("");
      setTempBankName({});
      setHideMandate("");
      setHideRTGS("d-none");
      setHideImidiatePayment("d-none");
      getMandateListCall();
    }
    document.getElementById('transferId').scrollIntoView(true);

  };
  const getMandateListCall = () => {
    getMandateList({ iin: tempProfileName?.IIN }, headerState).then((res) => {
      if (!res.data) {
        setMandateListData([]);
      } else {
        setMandateListData(res.data?.data);
      }
    });
  };

  const usersBankCall = (iin) => {
    usersBank({ iin }, headerState).then((res) => {
      setUsersBankDetail(res.data?.data);
    });
  };
  const changBankHandler = (e) => {
    if (e.target.value) {
      let bankDetailObj = JSON.parse(e.target.value);
      setBankName(e.target.value);
      setTempBankName(bankDetailObj);
    }
  };
  const amountHandler = (e) => {
    const inputValue = e.target.value;

    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ""); // Remove non-numeric characters
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    }
  };

  const changeSchemeHandler = (e) => {
    if (e.target.value) {
      let selectedData = JSON.parse(e.target.value);
      let reqBody = {
        schemecode: selectedData?.schemecode,
      };
      setScheme(e.target.value);
      setTempScheme(selectedData);
      getNSEProductCode(reqBody, headerState).then((res) => {
        if (!res.data) {
          notify("warn", "Please select another scheme");
        } else {
          setNseSchemeData({
            productcode: res.data?.data?.productcode,
            reinvestTag: res.data?.data?.REINVEST_TAG,
          });
        }
      });
    }
  };

  const addAdditionalPurchase = () => {
    if (amount && profileName && amc && scheme && folio) {
      if (amount < 5000) {
        notify("warn", "Amount should not be less than 5000");
        return;
      }
      document.getElementById('scroll').scrollIntoView(true);
      setAdditionalPurchaseDetails([
        ...additionalPurchaseDetails,
        {
          profileName: tempProfileName?.name,
          // amcName: tempAmc.amcName,
          amount,
          schemeName: tempScheme?.s_name,
          reinvest: nseSchemeData?.reinvestTag,
          product_code: nseSchemeData?.productcode,
          amc: amc,
          perpetual_flag: "N",
          folio: tempFolio?.folio,
          input_ref_no: "",
        },
      ]);

      setTempOrderData(amc, folio, scheme, amount);
      setAmc("");
      setFolio("");
      setScheme("");
      setAmount("");
      setSchemeList([]);
      setFolioList([]);
      setPaymentMode("");
      setBankName("");
      setTempBankName({});
      setMandateUrn("");
      setTempMandateUrn({});
    } else {
      notify("error", "All fields required");
    }
  };
  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value);
      let urnValue = JSON.parse(e.target.value);
      setTempMandateUrn(urnValue);
    }
  };

  function convertMonthNumberToName(monthNumber) {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    // Subtract 1 from monthNumber since JavaScript months are zero-based (January is 0)
    const monthIndex = monthNumber - 1;

    // Check if monthIndex is within the valid range
    if (monthIndex >= 0 && monthIndex < monthNames.length) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

  const orderAdditionalPurchase = () => {
    if (additionalPurchaseDetails.length === 0) {
      if (
        !amount ||
        !folio ||
        !amc ||
        !profileName ||
        !scheme ||
        !paymentMode
      ) {
        notify("error", "All fields required.");
        return;
      }
      if (amount < 5000) {
        notify("warn", "Amount should be greater than min amount");
        return;
      }
    }

    if (!bankName && paymentMode !== "M") {
      notify("error", "Please select bank name");
      return;
    }
    if (paymentMode === "M" && !mandateUrn) {
      notify("error", "Please select mandate ");
      return;
    }
    let arrayDate = [];
    if (transferDate) {
      arrayDate = transferDate.split("-");
    }
     setLoader("block")
    if (additionalPurchaseDetails.length === 0) {
      let reqData = {
        email: tempProfileName?.email,
        tax_status: tempProfileName?.taxStatus,
        sub_trxn_type: "N",
        trxn_acceptance: "ALL",
        payment_mode: paymentMode,
        instrm_amount: amount,
        debit_amount_type: "",
        Return_paymnt_flag:
          paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
        Client_callback_url: "API URL",
        ach_exist: "Y",
        amc: amc,
        product_code: nseSchemeData?.productcode,
        reinvest: nseSchemeData?.reinvestTag,
        amount: amount,
        input_ref_no: "",
        perpetual_flag: "N",
        insurance_enabled: "N",
        instrm_date: "",
        rtgs_code: paymentMode === "TR" ? tempBankName?.IFSC_CODE : "",
        transfer_date: transferDate
          ? arrayDate[2] +
          "-" +
          convertMonthNumberToName(arrayDate[1]) +
          "-" +
          arrayDate[0]
          : "",
        utr_no: utrNo,
        umrn: paymentMode === "M" ? tempMandateUrn?.UMRN_NO : "",
        folio: tempFolio?.folio,
        iin: tempProfileName?.IIN,
        accountNo:
          paymentMode === "M" ? tempMandateUrn?.ACCOUNT_NO : tempBankName?.AC_NO,
        bank_code:
          paymentMode === "M"
            ? tempMandateUrn?.BANK_CODE
            : tempBankName?.BANK_CODE,
        ifsc_code: paymentMode === "M" ? "" : tempBankName?.IFSC_CODE,
        branch: paymentMode === "M" ? "" : tempBankName?.BRANCH_NAME,
        holder_name: tempProfileName?.name,
      };
      newPurchase(reqData, headerState).then((res) => {
        setLoader("none")
        if (res.data?.status === 200) {
          setConfirmationData(res.data?.data);
          setConfirmShow(true);
          setShow(false);
          let nseData = {
            Unique_No: res.data?.data?.Unique_No,
            Trxn_No: res.data?.data?.Trxn_No,
            Application_No: res.data.data?.Application_No,
            Fund: res.data?.data?.Fund,
            Scheme: res.data?.data?.Scheme,
            Scheme_Name: res.data?.data?.Scheme_Name,
            Target_Scheme_Name: "",
            Source_Scheme_Name: "",
            Amt: res.data?.data.Amt,
            Status_Desc: res.data?.data?.Status_Desc,
            Status_code: res.data?.data?.Status_code,
            Folio: res.data?.data?.Folio,
            Platform: "Web",
            transaction_type: "Additional",
            iin: tempProfileName?.IIN,
            Pan: tempProfileName?.pan,
            Gpan: tempProfileName?.gpan ? tempProfileName?.gpan:"",
            Joint1pan: tempProfileName?.JOINT1_PAN,
            Joint2pan: tempProfileName?.JOINT2_PAN,
            name: tempProfileName?.name,
          };
          refreshState();
          setProfileName("");
          setTempProfileName({});
          saveNseTransaction(nseData, headerState).then((res) => { });

          if (res.data?.data?.Paymentlink) {
            window.open(res.data?.data?.Paymentlink);
          }
        } else {
          setConfirmationDataArr([]);
          setConfirmationData({});
          setErrorMsg(res.data?.msg);
          setErrorConfirmationShow(true);
        }
      });
    } else {
      const totalAmount = additionalPurchaseDetails.reduce(
        (accumulator, currentObject) => {
          return accumulator + Number(currentObject?.amount);
        },
        0
      );
      let reqData = {
        email: tempProfileName?.email,
        tax_status: tempProfileName?.taxStatus,
        sub_trxn_type: "N",
        trxn_acceptance: "ALL",
        payment_mode: paymentMode,
        instrm_amount: totalAmount,
        debit_amount_type: "",
        Return_paymnt_flag:
          paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
        Client_callback_url: "API URL",
        ach_exist: "Y",
        amc: amc,
        product_code: tempScheme?.productCode,
        reinvest: tempScheme?.reinvest,
        amount: totalAmount,
        input_ref_no: "",
        perpetual_flag: "N",
        insurance_enabled: "N",
        instrm_date: "",
        rtgs_code: paymentMode === "TR" ? tempBankName?.IFSC_CODE : "",
        transfer_date: transferDate
          ? arrayDate[2] +
          "-" +
          convertMonthNumberToName(arrayDate[1]) +
          "-" +
          arrayDate[0]
          : "",
        utr_no: utrNo ? utrNo : "",
        umrn: paymentMode === "M" ? tempMandateUrn?.UMRN_NO : "",
        folio: "",
        iin: tempProfileName.IIN,
        accountNo:
          paymentMode === "M" ? tempMandateUrn?.ACCOUNT_NO : tempBankName?.AC_NO,
        bank_code:
          paymentMode === "M"
            ? tempMandateUrn?.BANK_CODE
            : tempBankName?.BANK_CODE,
        ifsc_code: paymentMode === "M" ? "" : tempBankName?.IFSC_CODE,
        branch: paymentMode === "M" ? "" : tempBankName?.BRANCH_NAME,
        holder_name: tempProfileName?.name,
        schemes: additionalPurchaseDetails,
      };
      setLoader("block")
      multiplePurchase(reqData, headerState).then((res) => {
        if (res.data?.status === 200) {
          // setNewFolio(res.data?.message_full?.Folio)
          setConfirmationData(res.data?.data);
          setConfirmShow(true);
          setShow(false);
          setConfirmationDataArr(res.data?.data);
          let nseArr = res.data?.data;
          setLoader("none")
          for (let i = 0; i < nseArr.length; i++) {
            let nseData = {
              Unique_No: nseArr[i]?.Unique_No,
              Trxn_No: nseArr[i]?.Trxn_No,
              Application_No: nseArr[i]?.Application_No,
              Fund: nseArr[i]?.Fund,
              Scheme: nseArr[i]?.Scheme,
              Scheme_Name: nseArr[i]?.Scheme_Name,
              Amt: nseArr[i]?.Amt,
              Status_Desc: nseArr[i]?.Status_Desc,
              Status_code: nseArr[i]?.Status_code,
              Platform: "Web",
              transaction_type: "Additional",
              iin: tempProfileName?.IIN,
              Pan: tempProfileName?.pan,
              Gpan: tempProfileName?.gpan ? tempProfileName?.gpan:"",
              Joint1pan: tempProfileName?.jtname1,
              Joint2pan: tempProfileName?.jtname2,
              name: tempProfileName?.name,
            };
            saveNseTransaction(nseData, headerState).then((res) => {
              refreshState();
              setProfileName("");
              setTempProfileName({});
            });
          }
          setAdditionalPurchaseDetails([]);
          if (res.data?.data[0]?.Paymentlink) {
            window.open(res.data?.data[0]?.Paymentlink);
          }
        } else {
          setAdditionalPurchaseDetails([]);
          setConfirmationDataArr([]);
          // notify("error", res?.data?.msg)
          setConfirmationData({});
          setErrorMsg(res.data?.msg);
          setErrorConfirmationShow(true);
          setLoader("none")
        }
      });
    }
  };
  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
      setHideMandate("d-none")
      setPaymentMode("");
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  };
  return (
    <>
      <div className="">
        <Modal
          show={show}
          scrollable={true}
          onHide={() => setShow(false)}
          size="lg"
          className="invest-gray "
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">Additional Purchase</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4" style={{ scrollBehavior: "smooth" }}>
            <Form>
              <div className="row mb-2">
                <div className="col-md-4">
                  <Form.Group>
                    <Form.Label>
                      Select Profile <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={profileName}
                      onChange={isWantProfileChange}
                    >
                      <option value="">Select Profile</option>
                      {userProfileData.length > 0
                        ? userProfileData.map((el) => (
                          <option
                            value={JSON.stringify({
                              email: el?.EMAIL,
                              taxStatus: el?.TAX_STATUS,
                              IIN: el?.IIN,
                              name: el?.NAME,
                              pan: el?.PAN,
                              gpan: el?.GUARD_PAN,
                              jtname1: el?.JOINT_NAME1,
                              jtname2: el?.JOINT_NAME2,
                              nominee: el?.NOM_NAME,
                              JOINT1_PAN: el?.JOINT1_PAN,
                              JOINT2_PAN: el?.JOINT2_PAN,
                              JOINT1_VALIDPAN: el?.JOINT1_VALIDPAN,
                              JOINT2_VALIDPAN: el?.JOINT2_VALIDPAN,
                            })}
                            key={el?.PAN}
                          >
                            {`${el?.NAME} ${el?.JOINT_NAME1 && "|"} ${el?.JOINT_NAME1
                              }`}
                          </option>
                        ))
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group>
                    <Form.Label>
                      Select AMC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      placeholder="Select AMC"
                      onChange={amcChangeHandler}
                      value={amc}
                      disabled={amcLoader}
                    >
                      <option value="">Select AMC</option>
                      {amcListData &&
                        amcListData.map((el) => (
                          <option value={el?.amccode} key={el?.amccode}>
                            {el?.amc}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group>
                    <Form.Label>
                      Select Folio <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={folio}
                      onChange={changeFolioHandler}
                      disabled={folioLoader}
                    >
                      <option value="">Select folio</option>
                      {folioList &&
                        folioList.map((el) => (
                          <option
                            value={JSON.stringify({
                              folio: el.folio,
                              accord_schemecode: el.accord_schemecode,
                              rta: el.rta,
                            })}
                            key={el.PAN}
                          >
                            {el.folio}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-md-6">
                  <Form.Group
                    className="pt-lg-7 mt-lg-3 d-flex"
                    controlId="formBasicRadio"
                  >
                    <Form.Check
                      type="radio"
                      label="Existing Scheme"
                      name="existingScheme"
                      id="existingScheme"
                      className="pe-3 fs-sm-15"
                      value={"true"}
                      checked={schemeType == "true"}
                      onChange={schemeDataCall}
                    />
                    <Form.Check
                      type="radio"
                      label="New Scheme"
                      name="existingScheme"
                      value={"false"}
                      checked={schemeType == "false"}
                      onChange={schemeDataCall}
                      id="newScheme"
                      className="pe-3 fs-sm-15"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Select Scheme <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={scheme}
                      onChange={changeSchemeHandler}
                      disabled={schemeLoader}
                    >
                      <option value="">Select Scheme</option>
                      {schemeList.length > 0
                        ? schemeList.map((el) => {
                          return (
                            <option
                              value={JSON.stringify({
                                s_name: el.shortName,
                                minimumAmount: el.mininvt,
                                productCode: el.productCode,
                                schemecode: el.schemecode,
                                RTAamcCode: el.RTAamcCode,
                              })}
                              key={el.shortName}
                            >
                              {el.shortName}
                            </option>
                          );
                        })
                        : null}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-2">
                {schemeType === "false" ? (
                  <>
                    <div className="col-md-3">
                      <Form.Group className=" " controlId="formBasicRadio">
                        <Form.Label>
                          Asset Class <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Check
                            type="radio"
                            label="Equity"
                            value={"1"}
                            checked={assetClass == "1"}
                            onChange={assetClassHandler}
                            name="formHorizontalRadios1"
                            id="equity"
                            className="pe-3"
                          />
                          <Form.Check
                            type="radio"
                            label="debt"
                            value={"3"}
                            checked={assetClass == "3"}
                            onChange={assetClassHandler}
                            name="formHorizontalRadios1"
                            id="debt"
                            className="pe-3"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group className=" " controlId="formBasicRadio">
                        <Form.Label>
                          Option <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Check
                            type="radio"
                            label="Growth"
                            name="formHorizontalRadios2"
                            id="Growth"
                            value={"1"}
                            checked={option == "1"}
                            onChange={optionHandler}
                            className="pe-3"
                          />
                          <Form.Check
                            type="radio"
                            label="IDCW"
                            name="formHorizontalRadios2"
                            value={"2"}
                            checked={option == "2"}
                            onChange={optionHandler}
                            id="IDCW"
                            className="pe-3"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </>
                ) : null}

                <div className="col-lg-6 col-md-6">
                  <Form.Group>
                    <Form.Label>
                      Enter Amount <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={amountHandler}
                    />
                  </Form.Group>
                  <small className="text-label float-right">
                    Min Amount: 5000
                  </small>
                </div>
              </div>
              <div className="col-md-12 " onClick={addAdditionalPurchase}>
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger float-right my-4"
                >
                  + Add
                </a>
              </div>
              <div className="col-md-12 px-0">
                <div className="table-responsive w-100">
                  <table className="table custom bg-white mt-5 mb-3">
                    <tr>
                      <th className="wd-7rem">Profile</th>
                      <th className="wd-7rem">Scheme Name</th>
                      <th className="wd-7rem">Folio Number</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                    {additionalPurchaseDetails.length > 0
                      ? additionalPurchaseDetails.map((item, i) => {
                        return (
                          <tr>
                            <td>{item.profileName}</td>
                            <td>{item.schemeName}</td>
                            <td>{item.folio}</td>
                            <td>{item.amount}</td>
                            <td>
                              <Cart_Delete id={i} deleteData={deleteData} />
                            </td>
                          </tr>
                        );
                      })
                      : null}
                  </table>
                </div>
                <div className="row mb-2">
                  <div className="col-md-5" >
                    <Form.Group>
                      <Form.Label>
                        Select Payment Mode
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={paymentMode}
                        onChange={changePaymentModeHandler}
                      >
                        <option value={""}>Select Payment Mode</option>
                        <option value={"OL"}>Net Banking</option>
                        <option value={"UPI"}>UPI</option>
                        <option value={"TR"}>RTGS/NEFT</option>
                        <option value={"M"}>Debit Mandate</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className={`col-md-7 ${hideImidiatePayment}`}>
                    <Form.Group
                      className="pt-lg-4 mt-lg-3 d-flex"
                      controlId="formBasicRadio"
                    >
                      <Form.Check
                        type="radio"
                        label="Immediate Payment"
                        name="formHorizont"
                        id="ImmediatePayment"
                        className="pe-3 fs-sm-14"
                        value={"Y"}
                        checked={imediateOrEmail == "Y"}
                        onChange={(e) => setImediateOrEmail(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Link On Email"
                        name="formHorizont"
                        id="Link On Email"
                        className="pe-3 fs-sm-14"
                        value={"N"}
                        checked={imediateOrEmail == "N"}
                        onChange={(e) => setImediateOrEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className={`col-md-5 ${hideBank}`}>
                    <Form.Group>
                      <Form.Label>
                        Select Bank <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={bankName}
                        onChange={changBankHandler}
                      >
                        <option value="">Select Bank</option>
                        {usersBankDetail &&
                          usersBankDetail.map((el) => (
                            el.STATUS ==="Activated" ?  <option
                              value={JSON.stringify({
                                AC_NO: el.AC_NO,
                                BANK_CODE: el.BANK_CODE,
                                IFSC_CODE: el.IFSC_CODE,
                                BRANCH_NAME: el.BRANCH_NAME,
                                BANK_NAME: el?.BANK_NAME
                              })}
                            >{`${el.BANK_NAME} | Ac No- ${el.AC_NO}`}</option>:""
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className={`col-md-5 ${hideMandate}`}>
                    <Form.Group>
                      <Form.Label>
                        Select Mandate <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={mandateUrn}
                        onChange={changeMandateHandler}
                        onClick={checkMandate}
                      >
                        <option value={""}>Select Mandate</option>
                        {mandateListData.length > 0 &&
                          mandateListData.map((data) => (
                            <option
                              value={JSON.stringify({
                                UMRN_NO: data.UMRN_NO,
                                BANK_CODE: data.BANK_CODE,
                                ACCOUNT_NO: data.ACCOUNT_NO,
                                MANDATE_DATE: data.MANDATE_DATE,
                                AC_TYPE: data.AC_TYPE,
                              })}
                            >{`${data.BANK_NAME} | Ac No-  ${data.ACCOUNT_NO
                              } |  Amount- ${data?.AMOUNT > 0 ? data?.AMOUNT : "0"
                              }`}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </div>



                  <div className={`col-md-5 ${hideRTGS}`}>
                    <Form.Group>
                      <Form.Label>UTR No.</Form.Label>
                      <Form.Control
                        className="bg-c"
                        type="text"
                        placeholder="Enter UTR No"
                        value={utrNo}
                        onChange={(e) => {
                          if (e.target.value.length < 30) {
                            setUtrNo(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className={`col-md-5 ${hideRTGS}`}>
                  <Form.Group>
                    <Form.Label>Enter Transfer Date</Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="date"
                      placeholder="Enter Date"
                      max={removeFutureDAte}
                      value={transferDate}
                      id="anyWhere10"
                      onClick={() => dateAny("anyWhere10")}
                      onChange={(e) => setTransferDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                {paymentMode === "TR" ? <div className="col-md-12 mt-4">
                  <ul className="fs-13 mb-0  ps-0 lst-none">
                    <li>
                      <h6 className="text-red fs-14">Note :-</h6>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Name
                      <span className="text-black">
                        - NSE Clearing – New Mutual Fund Platform
                      </span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Bank Name
                      <span className="text-black">- HDFC Bank Ltd</span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Beneficiary Branch Name
                      <span className="text-black">-  Fort, Mumbai</span>
                    </li>

                    <li className="text-label">
                      <span className="text-red">*</span>Virtual Account No
                      <span className="text-black">
                        - ({`NSEMF221399${tempProfileName?.IIN ? tempProfileName?.IIN : ""}`})
                      </span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>Account Type
                      <span className="text-black">- Current Account</span>
                    </li>
                    <li className="text-label">
                      <span className="text-red">*</span>IFSC Code (For RTGS / NEFT Transfers)
                      <span className="text-black">- HDFC0000060</span>
                    </li>
                  </ul>
                </div> : ""}
                <div id="transferId"></div>
              </div>
            </Form>
            <div className="border-0 trans" >
              <button
                className="btn-custom"
                type="button"
                onClick={orderAdditionalPurchase}
              >
                Order Now
              </button>
            </div>
            <div id="scroll" className="py-4" ></div>
          </Modal.Body>
          {/* <Modal.Footer className="border-0">
            <button
              className="btn-custom"
              type="button"
              onClick={orderAdditionalPurchase}
            >
              Order Now
            </button>
          </Modal.Footer> */}

        </Modal>
      </div>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW
        show={errorConfirmationShow}
        setShows={setErrorConfirmationShow}
        errorMsg={errorMsg}
      />
      <ConfirmationProfileChangePopup
        show={isProfileChange}
        setShow={setIsProfileChange}
        massageContent={massageContent}
        profileChangeHandler={profileChangeHandler}
        profileTargetValue={profileTargetValue}
      />
      <ConfirmationMandatePopup
        show={isMandateAvailable}
        setShow={setIsMandateAvailable}
        profileDetail={{ name: tempProfileName?.name, iin: tempProfileName?.IIN, pan: tempProfileName?.pan, gpan: tempProfileName?.gpan }}
      />
    </>
  );
};
export default AddPurchaseTransact;
